/* eslint-disable default-case */
import React, { useEffect } from "react";

import { Card, Col, Form, Button } from "reactstrap";

import { IoIosArrowBack } from "react-icons/io";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../controllers/perfilAcessoController";

import Breadcrumb from "../../../components/breadcrumbs";

import { telasSistema } from "../../../util/telas";
import SimpleTab from "../../../components/SimpleTab/SimpleTab";
import ModulosLojasTab from "./Register/modulosLojasTab";
import AcoesTab from "./Edit/acoesTab";
import { tiposOperacaoSistema } from "../components/Acoes";

export function Edit() {
  const {
    perfilDeAcessoBeingEditedId,
    perfilDeAcessoBeingEditedData,
    activeTab,
    acoesTelas,
    acoesTelasInitialValueBackup,
    telasAssociadasInitialValueBackup,
  } = useSelector((state) => state.perfilAcesso);

  async function saveItem() {
    const novasTelasAssociadas = acoesTelas.flatMap((acaoTela) => {
      const telaBackup = acoesTelasInitialValueBackup.find(
        (acaoTelaBackup) =>
          acaoTelaBackup.id.toUpperCase() === acaoTela.id.toUpperCase()
      );

      if (!telaBackup) {
        return {
          id: acaoTela.id,
          permissoes: { ...acaoTela.permissoes },
        };
      }

      const permissoesModificadas = Object.keys(acaoTela.permissoes).filter(
        (permissao) =>
          telaBackup.permissoes[permissao] === false &&
          acaoTela.permissoes[permissao] === true
      );

      if (permissoesModificadas.length === 0) return [];

      return {
        id: acaoTela.id,
        permissoes: permissoesModificadas.reduce((acc, permissao) => {
          acc[permissao] = true;
          return acc;
        }, {}),
      };
    });

    const permissoesRemovidas = acoesTelas.flatMap((acaoTela) => {
      const telaBackup = acoesTelasInitialValueBackup.find(
        (acaoTelaBackup) =>
          acaoTelaBackup.id.toUpperCase() === acaoTela.id.toUpperCase()
      );

      if (!telaBackup) return [];

      const permissoesModificadas = Object.keys(acaoTela.permissoes).filter(
        (permissao) =>
          telaBackup.permissoes[permissao] === true &&
          acaoTela.permissoes[permissao] === false
      );

      if (permissoesModificadas.length === 0) return [];

      return {
        id: acaoTela.id,
        permissoes: permissoesModificadas.reduce((acc, permissao) => {
          acc[permissao] = false;
          return acc;
        }, {}),
      };
    });

    const telasRemovidas = telasAssociadasInitialValueBackup
      .filter(
        (acaoTelaBackup) =>
          !acoesTelas.some(
            (acaoTela) =>
              acaoTela.id.toUpperCase() === acaoTelaBackup.id.toUpperCase()
          )
      )
      .map((tela) => {
        const telaSistema = telasSistema.find(
          (telaSistema) =>
            telaSistema.id.toUpperCase() === tela.id.toUpperCase()
        );

        const permissoes = telaSistema
          ? Object.keys(telaSistema.options).reduce((acc, permissao) => {
              if (telaSistema.options[permissao] === true) {
                acc[permissao] = false;
              }
              return acc;
            }, {})
          : {};

        return {
          id: tela.id,
          permissoes: { ...permissoes, visualizar: false },
        };
      });

      const permissoesRemovidasMerged = new Map();

      [...permissoesRemovidas, ...telasRemovidas].forEach((tela) => {
        if (permissoesRemovidasMerged.has(tela.id)) {
          const existing = permissoesRemovidasMerged.get(tela.id);
          permissoesRemovidasMerged.set(tela.id, {
            ...existing,
            permissoes: { ...existing.permissoes, ...tela.permissoes },
          });
        } else {
          permissoesRemovidasMerged.set(tela.id, tela);
        }
      });
      
      const permissoesRemovidasUnicas = Array.from(permissoesRemovidasMerged.values());

    const payload = {
      ...perfilDeAcessoBeingEditedData,
      telasAssociadas: perfilDeAcessoBeingEditedData.telasAssociadas.map(
        (telaAssociada) => {
          const acoesNovas = novasTelasAssociadas
            .find(
              (acaoTela) =>
                acaoTela.id.toUpperCase() === telaAssociada.id.toUpperCase()
            );

          const acoesRemovidas = permissoesRemovidasUnicas.find(
            (acaoTela) =>
              acaoTela.id.toUpperCase() === telaAssociada.id.toUpperCase()
          );
          return {
            ...telaAssociada,
            permissoes: acoesNovas && { ...acoesNovas.permissoes },
            permissoesTelasRemover: acoesRemovidas && {
              ...acoesRemovidas.permissoes,
            },
          };
        }
      ),
    };
    const isSuccessed = await controller.updatePerfilDeAcesso(payload);

    if (isSuccessed) {
      actions.setActiveTab("Search");
      const data = await controller.getPerfisDeAcesso();
      actions.setPerfisDeAcesso(data);
    }
  }

  function changeTab(tab) {
    actions.setActiveTab(tab);
  }

  function handleVoltar() {
    changeTab("Search");
  }

  useEffect(() => {
    if (perfilDeAcessoBeingEditedId) {
      async function fetchPageInitialContent() {
        const responseData = {
          lojas: [],
          telas: [],
        };

        const lojasDisponiveis = await controller.getLojas();
        const telasDisponiveis = await controller.getTelas();

        actions.setLojasDisponiveisInitialValueBackup(lojasDisponiveis);

        actions.setTelasDisponiveisInitialValueBackup(telasDisponiveis);

        responseData.lojas = lojasDisponiveis;
        responseData.telas = telasDisponiveis;

        const { administrativo, descricao, hierarquico, id, lojas, telas } =
          await controller.getById(perfilDeAcessoBeingEditedId);

        const telasAssociadas = telas.map((tela) => {
          let permissoes = {
            criar: false,
            deletar: false,
            autorizar: false,
            filtros: false,
            estornar: false,
            desbloquear: false,
            exportar: false,
            tabelaCanal: false,
          };

          tela.permissoes.forEach((permission) => {
            const operationType = permission.id.toUpperCase();

            switch (operationType) {
              case "2A4A690A-01D2-4364-ABA4-6B2D977A3442":
                permissoes.criar = true;
                break;
              case "A02B98DB-5DD3-4803-91B6-CDE757D86C00":
                permissoes.deletar = true;
                break;
              case "1702FE06-7E33-48EE-BE6C-0B96B5209ACA":
                permissoes.estornar = true;
                break;
              case "D4BB230A-9583-4A04-9583-1A8B5D14F750":
                permissoes.autorizar = true;
                break;
              case "4EC5FA97-45ED-4659-901A-F50DCC4F5575":
                permissoes.filtros = true;
                break;
              case "CDDB1F17-A507-461C-A004-5B040EA330C9":
                permissoes.desbloquear = true;
                break;
                case "9C29FF4B-6305-47F7-83EA-0168CF7C88E4":
                  permissoes.exportar = true;
                  break;
                case "389E140D-9DC0-4E66-933C-A4C6BCDC2E78":
                  permissoes.tabelaCanal = true;
                  break;
                default:
                  break;
            }
          });

          let nomeTela = telasSistema.find(
            (telaSistema) => tela.id.toUpperCase() === telaSistema.id
          ).nomeTela;

          return {
            id: tela.id,
            nomeTela: nomeTela,
            permissoes: permissoes,
          };
        });

        // this is needed because API returns only the "loja" id
        const lojasAssociadas = [];

        lojas.forEach((loja) => {
          const lojaEquivalente = responseData.lojas.find((lojaDisponivel) => {
            return lojaDisponivel.id === loja.lojaId;
          });

          if (lojaEquivalente) {
            lojasAssociadas.push(lojaEquivalente);
          }
        });

        actions.setTelasAssociadas([...telasAssociadas]);
        actions.setTelasAssociadasInitialValueBackup([...telasAssociadas]);
        actions.setLojasAssociadas(lojasAssociadas);

        const telasDisponiveisWithoutAssociatedValues =
          responseData.telas.filter((telaDisponivel) => {
            return !telasAssociadas.some(
              (telaAssociada) => telaAssociada.id === telaDisponivel.id
            );
          });

        const lojasDisponiveisWithoutAssociatedValues =
          responseData.lojas.filter((lojaDisponivel) => {
            return !lojasAssociadas.some(
              (lojaAssociada) => lojaAssociada.id === lojaDisponivel.id
            );
          });

        actions.setTelasDisponiveis(telasDisponiveisWithoutAssociatedValues);
        actions.setLojasDisponiveis(lojasDisponiveisWithoutAssociatedValues);

        const telasFiltradas = telasSistema.filter((telaSistema) =>
          telasAssociadas.some(
            (telaAssociada) =>
              telaAssociada.id.toUpperCase() === telaSistema.id.toUpperCase()
          )
        );
        const acoesTelasAtualizadas = telasFiltradas.map((tela) => {
          const telaExistente = telasAssociadas.find(
            (t) => t.id.toUpperCase() === tela.id.toUpperCase()
          );
          const permissoesIniciais = tiposOperacaoSistema
            .filter(
              (tipoOperacao) => tela.options[tipoOperacao.option] === true
            )
            .reduce((acc, tipoOperacao) => {
              acc[tipoOperacao.option] = telaExistente
                ? telaExistente.permissoes[tipoOperacao.option] || false
                : false;
              return acc;
            }, {});

          return {
            id: tela.id,
            nomeTela: tela.nomeTela,
            permissoes: permissoesIniciais,
          };
        });
        actions.setAcoesTelas([...acoesTelasAtualizadas]);
        actions.setAcoesTelasInitialValueBackup([...acoesTelasAtualizadas]);

        actions.setPerfilDeAcessoBeingEditedData({ campo: "id", value: id });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "descricao",
          value: descricao,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "isHierarquia",
          value: hierarquico,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "isAdministrativo",
          value: administrativo,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "telasAssociadas",
          value: telasAssociadas,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "telasDisponiveis",
          value: telasDisponiveis,
        });
      }

      // clear previous data
      actions.setTelasAssociadas([]);
      actions.setLojasAssociadas([]);
      actions.setTelasDisponiveis([]);
      actions.setLojasDisponiveis([]);
      actions.setTelasSelecionadas([]);
      actions.setLojasSelecionadas([]);
      actions.setAcoesTelasInitialValueBackup([]);
      actions.setTelasAssociadasInitialValueBackup([]);
      actions.clearPerfilDeAcessoBeingEditedData();
      actions.setTelasAssociadasSearchTerm("");
      actions.setTelasDisponiveisSearchTerm("");
      actions.setLojasAssociadasSearchTerm("");
      actions.setLojasDisponiveisSearchTerm("");

      fetchPageInitialContent();
    }
  }, [perfilDeAcessoBeingEditedId]);

  return (
    <div className="d-flex flex-column row-gap-15">
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card>
          <Form>
            <Col className="pt-2 pb-2">
              <Button
                onClick={() => {
                  handleVoltar();
                }}
                style={{ width: "150px", color: "white" }}
                className="purpleButton"
              >
                <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                Voltar
              </Button>
              <Button
                onClick={() => saveItem()}
                style={{ width: "242px", float: "right" }}
                className="purpleButton"
              >
                Salvar Cadastro
              </Button>
            </Col>
          </Form>
        </Card>
      </Col>
      <Col>
        <Card className="radius pb-2 px-5">
          <SimpleTab
            tabs={[
              {
                id: 0,
                descricao: "Telas/Lojas",
                component: <ModulosLojasTab />,
              },
              {
                id: 1,
                descricao: "Ações",
                component: <AcoesTab />,
              },
            ]}
          />
        </Card>
      </Col>
    </div>
  );
}
