import React from "react";
import Acoes from "../../components/Acoes";
import { StyledCard } from "../../style";

const AcoesTab = () => {
  return (
    <StyledCard>
      <div className="p-4 d-flex justify-content-center">
        <Acoes />
      </div>
    </StyledCard>
  );
};

export default AcoesTab;
