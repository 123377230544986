import React from "react";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import { Col, Row } from "reactstrap";
import { Tooltip } from "@material-ui/core";

import { TelasDisponiveisList } from "./TelasDisponiveisList";
import { TelasAssociadasList } from "./TelasAssociadasList";

import { useSelector } from "react-redux";
import * as actions from "../../../../store/modules/perfilAcesso/actions";

function AssociacaoTelas() {
  const {
    telasSelecionadas,
    telasAssociadas,
    telasAssociadasSelecionadas,
    telasDisponiveisInitialValueBackup,
    isInEditMode,
    telasDisponiveis,
    activeTab,
    telasDisponiveisSearchResults,
    perfilDeAcessoBeingEditedData
  } = useSelector((state) => state.perfilAcesso);

  async function handleAddAllScreensSelectedToAssociatedScreens() {
    const telasSelecionadasMapped = telasSelecionadas.map(
      ({ id, nomeTela }) => {
        return {
          id,
          nomeTela,
          permissoes: {
            criar: false,
            deletar: false,
            estornar: false,
            autorizar: false,
            filtros: false,
            desbloquear: false,
            exportar: false,
            tabelaCanal: false,
          },
        };
      }
    );

    if (isInEditMode) {
      const newTelasAssociadas = [...telasSelecionadasMapped];

      actions.setPerfilDeAcessoBeingEditedData({
        campo: "telasAssociadas",
        value: [...newTelasAssociadas, ...perfilDeAcessoBeingEditedData.telasAssociadas],
      });

      // clear
      actions.setTelasAssociadasSearchResults([]);

      actions.setTelasAssociadas([...newTelasAssociadas, ...telasAssociadas]);

      const telasDisponiveisWithoutAssociatedValues = telasDisponiveis.filter(
        (telaDisponivel) => {
          return ![...newTelasAssociadas, ...telasAssociadas].some(
            (telaAssociada) => telaAssociada.id === telaDisponivel.id
          );
        }
      );

      actions.setTelasSelecionadas([]);
      actions.setTelasDisponiveisSelectedSearchResults([]);
      actions.setTelasDisponiveis(telasDisponiveisWithoutAssociatedValues);
      actions.setTelasDisponiveisSearchTerm("");

      if (
        telasDisponiveisSearchResults.length > 0 &&
        telasDisponiveisSearchResults.length !== 1
      ) {
        const telasDisponiveisSearchResultsWithoutAssociatedValues =
          telasDisponiveisSearchResults.filter((telaDisponivel) => {
            return ![...newTelasAssociadas, ...telasAssociadas].some(
              (telaAssociada) => telaAssociada.id === telaDisponivel.id
            );
          });

        actions.setTelasDisponiveisSearchResults(
          telasDisponiveisSearchResultsWithoutAssociatedValues
        );
      }
    } else {
      const newTelasAssociadas = [...telasSelecionadasMapped];

      actions.setPerfilDeAcessoBeingCreatedData({
        campo: "telasAssociadas",
        value: [...newTelasAssociadas, ...telasAssociadas],
      });

      // clear
      actions.setTelasAssociadasSearchResults([]);

      actions.setTelasAssociadas([...newTelasAssociadas, ...telasAssociadas]);

      const telasDisponiveisWithoutAssociatedValues = telasDisponiveis.filter(
        (telaDisponivel) => {
          return ![...newTelasAssociadas, ...telasAssociadas].some(
            (telaAssociada) => telaAssociada.id === telaDisponivel.id
          );
        }
      );

      actions.setTelasSelecionadas([]);
      actions.setTelasDisponiveisSelectedSearchResults([]);
      actions.setTelasDisponiveis(telasDisponiveisWithoutAssociatedValues);
      actions.setTelasDisponiveisSearchTerm("");

      if (
        telasDisponiveisSearchResults.length > 0 &&
        telasDisponiveisSearchResults.length !== 1
      ) {
        const telasDisponiveisSearchResultsWithoutAssociatedValues =
          telasDisponiveisSearchResults.filter((telaDisponivel) => {
            return ![...newTelasAssociadas, ...telasAssociadas].some(
              (telaAssociada) => telaAssociada.id === telaDisponivel.id
            );
          });

        actions.setTelasDisponiveisSearchResults(
          telasDisponiveisSearchResultsWithoutAssociatedValues
        );
      }
    }
  }

  async function handleRemoveAllFromAssociatedScreens() {
    actions.setTelasDisponiveisSearchResults([]);
    actions.setTelasDisponiveisSelectedSearchResults([]);
    actions.setTelasDisponiveisSearchTerm("");

    actions.setTelasAssociadasSearchResults([]);
    actions.setTelasAssociadasSearchTerm("");

    actions.setTelasSelecionadas([]);

    if (telasAssociadas.length > 0 && isInEditMode) {
      const newTelasDisponiveisValue = [
        ...telasDisponiveisInitialValueBackup.filter((tela) => {
          return telasAssociadasSelecionadas.some(
            (telaAssociadaSelecionada) =>
              telaAssociadaSelecionada.id === tela.id
          );
        }),
        ...telasDisponiveisInitialValueBackup.filter((tela) => {
          return telasDisponiveis.some(
            (telaDisponivel) => telaDisponivel.id === tela.id
          );
        }),
      ];

      const newTelasAssociadasValue = telasAssociadas.filter(
        (telaAssociada) => {
          return !telasAssociadasSelecionadas.some(
            (telaAssociadaSelecionada) =>
              telaAssociadaSelecionada.id === telaAssociada.id
          );
        }
      );

      actions.setTelasAssociadas(newTelasAssociadasValue);
      actions.setTelasDisponiveis(newTelasDisponiveisValue);
    }

    if (telasAssociadas.length > 0 && !isInEditMode) {
      const newTelasDisponiveisValue = [
        ...telasDisponiveisInitialValueBackup.filter((tela) => {
          return telasAssociadasSelecionadas.some(
            (telaAssociadaSelecionada) =>
              telaAssociadaSelecionada.id === tela.id
          );
        }),
        ...telasDisponiveisInitialValueBackup.filter((tela) => {
          return telasDisponiveis.some(
            (telaDisponivel) => telaDisponivel.id === tela.id
          );
        }),
      ];

      const newTelasAssociadasValue = telasAssociadas.filter(
        (telaAssociada) => {
          return !telasAssociadasSelecionadas.some(
            (telaAssociadaSelecionada) =>
              telaAssociadaSelecionada.id === telaAssociada.id
          );
        }
      );

      actions.setTelasAssociadas(newTelasAssociadasValue);
      actions.setTelasDisponiveis(newTelasDisponiveisValue);
    }

    actions.setTelasAssociadasSelecionadas([]);
  }

  return (
    <div>
      <Row>
        <TelasDisponiveisList />
        <Col
          md={2}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {!(activeTab === "Visualize") && (
            <>
              <Tooltip title="Adicionar telas selecionadas" placement="right">
                <div
                  onClick={() =>
                    handleAddAllScreensSelectedToAssociatedScreens()
                  }
                  style={{ cursor: "pointer", alignSelf: "center" }}
                >
                  <MdKeyboardArrowRight style={{ fontSize: "80px" }} />
                </div>
              </Tooltip>
              <Tooltip
                title="Remover todas as telas associadas"
                placement="right"
              >
                <div
                  style={{ cursor: "pointer", alignSelf: "center" }}
                  onClick={() => handleRemoveAllFromAssociatedScreens()}
                >
                  <MdKeyboardArrowLeft style={{ fontSize: "80px" }} />
                </div>
              </Tooltip>
            </>
          )}
        </Col>
        <TelasAssociadasList />
      </Row>
    </div>
  );
}

export default AssociacaoTelas;
