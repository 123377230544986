/* eslint-disable default-case */
/* eslint-disable no-use-before-define */
import { RiCloseFill } from "react-icons/ri";
import * as BiIcons from "react-icons/bi";

import { Col, Label, Row } from "reactstrap";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

import { useSelector } from "react-redux";

import * as actions from "../../../../store/modules/perfilAcesso/actions";
import LoadingIcon from "../../../../components/Loading/LoadingIcon";

import { AssociacaoContainer, CheckboxAssociacao } from "../../style";

export function TelasAssociadasList() {
  const {
    telasAssociadas,
    telasDisponiveis,
    telasAssociadasSelecionadas,
    telasAssociadasSelectedSearchResults,
    activeTab,
    telasAssociadasSearchResults,
    telasAssociadasSearchTerm,
    telasDisponiveisInitialValueBackup,
    loadingTelas,
  } = useSelector((state) => state.perfilAcesso);

  const telas =
    telasAssociadasSearchTerm.length > 0
      ? telasAssociadasSearchResults
      : telasAssociadas;

  const isAllSearchResultsSelected =
    telasAssociadasSearchResults.length > 0 &&
    telasAssociadasSearchResults.length ===
      telasAssociadasSelectedSearchResults.length;

  const isAllSelected =
    telasAssociadasSearchTerm.length > 0
      ? isAllSearchResultsSelected
      : telasAssociadasSelecionadas.length > 0 &&
        telasAssociadasSelecionadas.length === telasAssociadas.length;

  const isTelasAssociadasEmpty = telasAssociadas.length === 0;

  const isSelectAllVisible =
    telasAssociadasSearchTerm.length > 0
      ? telasAssociadasSearchResults.length > 0
      : !(isTelasAssociadasEmpty || activeTab === "Visualize");

  function handleSearchTelasAssociadas(inputValue) {
    actions.setTelasAssociadasSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setTelasAssociadasSearchTerm("");
      actions.setTelasAssociadasSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();
    const telasFiltradas = telasAssociadas.filter((tela) =>
      tela.nomeTela.toLowerCase().includes(searchTermLowercase)
    );

    actions.setTelasAssociadasSearchResults(telasFiltradas);

    actions.setTelasAssociadasSelectedSearchResults(
      telasFiltradas.filter((telaAssociada) =>
        telasAssociadasSelecionadas.some(
          (telaSelecionada) => telaSelecionada.id === telaAssociada.id
        )
      )
    );
  }

  async function handleRemoveScreenFromAssociatedList(telaAssociada) {
    if (telasAssociadasSearchResults.length === 1) {
      //clear search input text
      actions.setTelasAssociadasSearchTerm("");
    }

    if (
      telasAssociadasSearchResults.length > 0 &&
      telasAssociadasSearchResults.length !== 1
    ) {
      const newTelasAssociadasSearchResults =
        telasAssociadasSearchResults.filter(
          (tela) => tela.id !== telaAssociada.id
        );
      actions.setTelasAssociadasSearchResults(newTelasAssociadasSearchResults);
    }

    const newTelasAssociadas = telasAssociadas.filter(
      (tela) => tela.id !== telaAssociada.id
    );

    const removedItem = telasDisponiveisInitialValueBackup.find(
      (tela) => tela.id === telaAssociada.id
    );

    actions.setTelasAssociadas(newTelasAssociadas);
    if (removedItem !== undefined) {
      actions.setTelasDisponiveis([removedItem, ...telasDisponiveis]);
    }

    actions.setTelasAssociadasSelecionadas(
      telasAssociadasSelecionadas.filter(
        (telaSelecionada) => telaSelecionada.id !== telaAssociada.id
      )
    );
    actions.setTelasAssociadasSelectedSearchResults(
      telasAssociadasSelectedSearchResults.filter(
        (telaSelecionada) => telaSelecionada.id !== telaAssociada.id
      )
    );
    actions.setTelasDisponiveisSearchResults([]);
    actions.setTelasDisponiveisSelectedSearchResults([]);
    actions.setTelasDisponiveisSearchTerm("");
  }

  function handleSelectAllTelasAssociadas() {
    if (telasAssociadasSearchTerm.length > 0) {
      const searchResultsSelected = telasAssociadasSearchResults.filter(
        (telaAssociada) =>
          telasAssociadasSelecionadas.some(
            (telaAssociadaSelecionada) =>
              telaAssociadaSelecionada.id === telaAssociada.id
          )
      );

      const isAllSearchResultsSelectedd =
        searchResultsSelected.length > 0 &&
        searchResultsSelected.length === telasAssociadasSearchResults.length;

      const telasAssociadasSelecionadasWithoutSelectedSearchResultsItems =
        telasAssociadasSelecionadas.filter(
          (tela) =>
            !searchResultsSelected.some(
              (telaAssociadaSelecionada) =>
                telaAssociadaSelecionada.id === tela.id
            )
        );

      if (isAllSearchResultsSelectedd) {
        actions.setTelasAssociadasSelecionadas(
          telasAssociadasSelecionadasWithoutSelectedSearchResultsItems
        );

        actions.setTelasAssociadasSelectedSearchResults([]);
      } else {
        actions.setTelasAssociadasSelecionadas([
          ...telasAssociadasSelecionadasWithoutSelectedSearchResultsItems,
          ...telasAssociadasSearchResults,
        ]);

        actions.setTelasAssociadasSelectedSearchResults(
          telasAssociadasSearchResults
        );
      }

      return;
    }

    if (isAllSelected) {
      actions.setTelasAssociadasSelecionadas([]);
    } else {
      actions.setTelasAssociadasSelecionadas([...telasAssociadas]);
    }
  }

  function handleSelecionarTelaAssociada(telaId) {
    const telaSelecionada = telasAssociadas.find((tela) => tela.id === telaId);
    if (telaSelecionada !== undefined) {
      actions.setTelasAssociadasSelecionadas([
        ...telasAssociadasSelecionadas,
        telaSelecionada,
      ]);
    }

    if (telasAssociadasSearchTerm.length > 0) {
      actions.setTelasAssociadasSelectedSearchResults([
        ...telasAssociadasSelectedSearchResults,
        telaSelecionada,
      ]);
    }
  }

  function handleDeselecionarTelaAssociada(telaId) {
    actions.setTelasAssociadasSelecionadas(
      [...telasAssociadasSelecionadas].filter((tela) => tela.id !== telaId)
    );

    if (telasAssociadasSearchTerm.length > 0) {
      actions.setTelasAssociadasSelectedSearchResults(
        [...telasAssociadasSelectedSearchResults].filter(
          (tela) => tela.id !== telaId
        )
      );
    }
  }


  function checkIfTelaAssociadaIsSelecionada(telaId) {
    const isTelaSelecionada = telasAssociadasSelecionadas.some(
      (tela) => tela.id === telaId
    );

    return isTelaSelecionada;
  }

  return (
    <Col md={5}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Módulos Associados
      </Label>
      <OutlinedInput
        className="input-default-grey mb-2"
        placeholder="Buscar"
        value={telasAssociadasSearchTerm}
        onChange={(e) => handleSearchTelasAssociadas(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <BiIcons.BiSearchAlt />
          </InputAdornment>
        }
      />
      <AssociacaoContainer>
        {isSelectAllVisible ? (
          <div className="container-fluid">
            <Row className="row-item">
              <Col md={1} className="d-flex align-items-center">
                <label className="custom-checkbox">
                  <CheckboxAssociacao
                    id="telas-associadas-selectAll"
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={() => handleSelectAllTelasAssociadas()}
                  />
                  <label htmlFor="telas-associadas-selectAll"></label>
                </label>
              </Col>
              <Col className="d-flex align-items-center">
                <p
                  style={{
                    whiteSpace: "nowrap",
                    color: "var(--color-preto)",
                    marginBottom: 0,
                  }}
                >
                  Selecionar todos
                </p>
              </Col>
            </Row>
          </div>
        ) : null}
        {loadingTelas && <LoadingIcon color="var(--color-roxo)" />}
        {telas
          .sort((a, b) => a.nomeTela?.localeCompare(b.nomeTela))
          .map((tela) => {
            return (
              <div key={tela.nomeTela} className="container-fluid">
                <Row className="row-item">
                  {!(activeTab === "Visualize") && (
                    <Col md={1} className="d-flex align-items-center">
                      <label className="custom-checkbox">
                        <CheckboxAssociacao
                          id={`checkbox-telas-associadas-${tela.id}`}
                          key={tela.id}
                          type="checkbox"
                          checked={checkIfTelaAssociadaIsSelecionada(tela.id)}
                          onChange={() => {
                            checkIfTelaAssociadaIsSelecionada(tela.id)
                              ? handleDeselecionarTelaAssociada(tela.id)
                              : handleSelecionarTelaAssociada(tela.id);
                          }}
                        />
                        <label
                          htmlFor={`checkbox-telas-associadas-${tela.id}`}
                        ></label>
                      </label>
                    </Col>
                  )}
                  <Col className="d-flex align-items-center">
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        color: "var(--color-preto)",
                        marginBottom: 0,
                      }}
                    >
                      {tela.nomeTela}
                    </p>
                  </Col>
                  {!(activeTab === "Visualize") && (
                    <Col md={1}>
                      <RiCloseFill
                        onClick={() =>
                          handleRemoveScreenFromAssociatedList(tela)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
      </AssociacaoContainer>
    </Col>
  );
}
