import React from "react";
import { StyledCard } from "../../style";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as actions from "../../../../store/modules/perfilAcesso/actions";
import { removeAccentsAndUppercase } from "../../../../util/masks";
import { Switch } from "../../../../components/Inputs/Input";
import AssociacaoTelas from "../../components/AssociacaoTelas";
import AssociacaoLojas from "../../components/AssociacaoLojas";

const ModulosLojasTab = () => {
  const { perfilDeAcessoBeingCreatedData } = useSelector(
    (state) => state.perfilAcesso
  );
  return (
    <div className="d-flex flex-column">
      <StyledCard>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label
                style={{
                  fontWeight: "700",
                  color: "var(--color-preto)",
                }}
              >
                Perfil
              </Label>
              <Input
                type="text"
                placeholder="Digite aqui..."
                value={perfilDeAcessoBeingCreatedData.descricao}
                onChange={(e) =>
                  actions.setPerfilDeAcessoBeingCreatedData({
                    campo: "descricao",
                    value: removeAccentsAndUppercase(e.target.value),
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Switch
              componentRight="Este perfil irá utilizar a hierarquia como filtro de visualização?"
              className="switch"
              checked={perfilDeAcessoBeingCreatedData.isHierarquia}
              onChange={() =>
                actions.setPerfilDeAcessoBeingCreatedData({
                  campo: "isHierarquia",
                  value: !perfilDeAcessoBeingCreatedData.isHierarquia,
                })
              }
            />
          </Col>
          <Col md={6}>
            <Switch
              componentRight="Exibir CPF?"
              className="switch"
              checked={perfilDeAcessoBeingCreatedData.isAdministrativo}
              onChange={() =>
                actions.setPerfilDeAcessoBeingCreatedData({
                  campo: "isAdministrativo",
                  value: !perfilDeAcessoBeingCreatedData.isAdministrativo,
                })
              }
            />
          </Col>
        </Row>
      </StyledCard>
      <StyledCard>
        <div style={{ padding: "20px" }}>
          <AssociacaoTelas />
        </div>
      </StyledCard>
      {!perfilDeAcessoBeingCreatedData.isHierarquia && (
        <StyledCard>
          <div style={{ padding: "20px" }}>
            <AssociacaoLojas />
          </div>
        </StyledCard>
      )}
    </div>
  );
};

export default ModulosLojasTab;
