import { Col, Label, Row } from "reactstrap";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

import * as BiIcons from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";

import { useSelector } from "react-redux";

import * as actions from "../../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../../controllers/perfilAcessoController";

import LoadingIcon from "../../../../components/Loading/LoadingIcon";
import "../styles.css";
import { AssociacaoContainer, CheckboxAssociacao } from "../../style";

export function LojasAssociadasList() {
  const {
    lojasDisponiveis,
    lojasAssociadas,
    lojasAssociadasSelecionadas,
    lojasAssociadasSelectedSearchResults,
    perfilDeAcessoBeingEditedId,
    lojasDisponiveisInitialValueBackup,
    loadingLojas,
    isInEditMode,
    activeTab,
    lojasAssociadasSearchResults,
    lojasAssociadasSearchTerm,
  } = useSelector((state) => state.perfilAcesso);

  const lojas =
    lojasAssociadasSearchTerm.length > 0
      ? lojasAssociadasSearchResults
      : lojasAssociadas;

  const isAllSearchResultsSelected =
    lojasAssociadasSearchResults.length > 0 &&
    lojasAssociadasSearchResults.length ===
      lojasAssociadasSelectedSearchResults.length;

  const isAllSelected =
    lojasAssociadasSearchTerm.length > 0
      ? isAllSearchResultsSelected
      : lojasAssociadasSelecionadas.length > 0 &&
        lojasAssociadasSelecionadas.length === lojasAssociadas.length;

  const isLojasAssociadasEmpty = lojasAssociadas.length === 0;

  const isSelectAllVisible =
    lojasAssociadasSearchTerm.length > 0
      ? lojasAssociadasSearchResults.length > 0
      : !(isLojasAssociadasEmpty || activeTab === "Visualize");

  function handleSearchLojasAssociadas(inputValue) {
    actions.setLojasAssociadasSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setLojasAssociadasSearchTerm("");
      actions.setLojasAssociadasSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();
    const lojasFiltradas = lojasAssociadas.filter((loja) =>
      loja.nome.toLowerCase().includes(searchTermLowercase)
    );

    actions.setLojasAssociadasSearchResults(lojasFiltradas);

    actions.setLojasAssociadasSelectedSearchResults(
      lojasFiltradas.filter((lojaAssociada) =>
        lojasAssociadasSelecionadas.some(
          (lojaSelecionada) => lojaSelecionada.id === lojaAssociada.id
        )
      )
    );
  }

  async function handleRemoveLojasFromAssociatedList(lojaId) {
    let isSuccessed = true;

    if (isInEditMode) {
      const hasSuccessed = await controller.removeLojaAssociada({
        perfilDeAcessoId: perfilDeAcessoBeingEditedId,
        lojaId: lojaId,
      });

      isSuccessed = hasSuccessed;
    }

    if (!isSuccessed) {
      return;
    }

    if (lojasAssociadasSearchResults.length === 1) {
      // clear lojas associadas search input text
      actions.setLojasAssociadasSearchTerm("");
    }

    if (
      lojasAssociadasSearchResults.length > 0 &&
      lojasAssociadasSearchResults.length !== 1
    ) {
      const newLojasAssociadasSearchResults =
        lojasAssociadasSearchResults.filter((loja) => loja.id !== lojaId);
      actions.setLojasAssociadasSearchResults(newLojasAssociadasSearchResults);
    }

    const newLojasAssociadas = lojasAssociadas.filter(
      (loja) => loja.id !== lojaId
    );

    const removedItem = lojasDisponiveisInitialValueBackup.find(
      (loja) => loja.id === lojaId
    );

    actions.setLojasAssociadas(newLojasAssociadas);
    if (removedItem !== undefined) {
      actions.setLojasDisponiveis([removedItem, ...lojasDisponiveis]);
    }

    actions.setLojasAssociadasSelecionadas(
      lojasAssociadasSelecionadas.filter(
        (lojaSelecionada) => lojaSelecionada.id !== lojaId
      )
    );
    actions.setLojasAssociadasSelectedSearchResults(
      lojasAssociadasSelectedSearchResults.filter(
        (lojaSelecionada) => lojaSelecionada.id !== lojaId
      )
    );
    actions.setLojasDisponiveisSearchResults([]);
    actions.setLojasDisponiveisSelectedSearchResults([]);
    actions.setLojasDisponiveisSearchTerm("");
  }

  function handleSelectAllLojasAssociadas() {
    if (lojasAssociadasSearchTerm.length > 0) {
      const searchResultsSelected = lojasAssociadasSearchResults.filter(
        (lojaAssociada) =>
          lojasAssociadasSelecionadas.some(
            (lojaAssociadaSelecionada) =>
              lojaAssociadaSelecionada.id === lojaAssociada.id
          )
      );

      const isAllSearchResultsSelectedd =
        searchResultsSelected.length > 0 &&
        searchResultsSelected.length === lojasAssociadasSearchResults.length;

      const lojasAssociadasSelecionadasWithoutSelectedSearchResultsItems =
        lojasAssociadasSelecionadas.filter(
          (loja) =>
            !searchResultsSelected.some(
              (lojaAssociadaSelecionada) =>
                lojaAssociadaSelecionada.id === loja.id
            )
        );

      if (isAllSearchResultsSelectedd) {
        actions.setLojasAssociadasSelecionadas(
          lojasAssociadasSelecionadasWithoutSelectedSearchResultsItems
        );

        actions.setLojasAssociadasSelectedSearchResults([]);
      } else {
        actions.setLojasAssociadasSelecionadas([
          ...lojasAssociadasSelecionadasWithoutSelectedSearchResultsItems,
          ...lojasAssociadasSearchResults,
        ]);

        actions.setLojasAssociadasSelectedSearchResults(
          lojasAssociadasSearchResults
        );
      }

      return;
    }

    if (isAllSelected) {
      actions.setLojasAssociadasSelecionadas([]);
    } else {
      actions.setLojasAssociadasSelecionadas([...lojasAssociadas]);
    }
  }

  function handleSelecionarLojaAssociada(lojaId) {
    const lojaSelecionada = lojasAssociadas.find((loja) => loja.id === lojaId);
    if (lojaSelecionada !== undefined) {
      actions.setLojasAssociadasSelecionadas([
        ...lojasAssociadasSelecionadas,
        lojaSelecionada,
      ]);
    }

    if (lojasAssociadasSearchTerm.length > 0) {
      actions.setLojasAssociadasSelectedSearchResults([
        ...lojasAssociadasSelectedSearchResults,
        lojaSelecionada,
      ]);
    }
  }

  function handleDeselecionarLojaAssociada(lojaId) {
    actions.setLojasAssociadasSelecionadas(
      [...lojasAssociadasSelecionadas].filter((loja) => loja.id !== lojaId)
    );

    if (lojasAssociadasSearchTerm.length > 0) {
      actions.setLojasAssociadasSelectedSearchResults(
        [...lojasAssociadasSelectedSearchResults].filter(
          (loja) => loja.id !== lojaId
        )
      );
    }
  }

  function checkIfLojaAssociadaIsSelecionada(lojaId) {
    const isLojaSelecionada = lojasAssociadasSelecionadas.some(
      (loja) => loja.id === lojaId
    );

    return isLojaSelecionada;
  }

  return (
    <Col md={5}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Lojas Associadas
      </Label>
      <OutlinedInput
        className="input-default-grey mb-2"
        placeholder="Buscar"
        value={lojasAssociadasSearchTerm}
        onChange={(e) => handleSearchLojasAssociadas(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <BiIcons.BiSearchAlt />
          </InputAdornment>
        }
      />
      <AssociacaoContainer>
        {isSelectAllVisible ? (
          <div className="container-fluid">
            <Row className="row-item">
              <Col md={1} className="d-flex align-items-center">
                <label className="custom-checkbox">
                  <CheckboxAssociacao
                    id="lojas-associadas-selectAll"
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={() => handleSelectAllLojasAssociadas()}
                  />
                  <label htmlFor="lojas-associadas-selectAll"></label>
                </label>
              </Col>
              <Col className="d-flex align-items-center">
                <p
                  style={{
                    whiteSpace: "nowrap",
                    color: "var(--color-preto)",
                    marginBottom: 0,
                  }}
                >
                  Selecionar todos
                </p>
              </Col>
            </Row>
          </div>
        ) : null}
        {loadingLojas && <LoadingIcon color="var(--color-roxo)" />}
        {lojas
          .sort((a, b) => a.nome.localeCompare(b.nome))
          .map((loja, i) => {
            return (
              <div key={loja.id} className="container-fluid">
                <Row className="row-item">
                  {!(activeTab === "Visualize") && (
                    <Col md={1} className="d-flex align-items-center">
                      <label className="custom-checkbox">
                        <CheckboxAssociacao
                          id={`checkbox-loja-associada-${loja.id}`}
                          key={loja.id}
                          type="checkbox"
                          checked={checkIfLojaAssociadaIsSelecionada(loja.id)}
                          onChange={() => {
                            checkIfLojaAssociadaIsSelecionada(loja.id)
                              ? handleDeselecionarLojaAssociada(loja.id)
                              : handleSelecionarLojaAssociada(loja.id);
                          }}
                        />
                        <label
                          htmlFor={`checkbox-loja-associada-${loja.id}`}
                        ></label>
                      </label>
                    </Col>
                  )}
                  <Col className="d-flex align-items-center">
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        color: "var(--color-preto)",
                        marginBottom: 0,
                      }}
                    >
                      {loja.nome}
                    </p>
                  </Col>
                  {!(activeTab === "Visualize") && (
                    <Col md={1}>
                      <RiCloseFill
                        onClick={() =>
                          handleRemoveLojasFromAssociatedList(loja.id)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
      </AssociacaoContainer>
    </Col>
  );
}
