import { darken } from "@material-ui/core/styles";
import { Card } from "reactstrap";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  padding: 16px 20px;
  border-radius: 16px;
  border: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 2rem;
`;

export const AssociacaoContainer = styled.div`
  border: 1px solid rgba(242, 244, 247, 1);
  border-radius: 10px;
  height: 350px;
  overflow: auto;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  & p {
    font-family: "inter";
    font-weight: 500;
    font-size: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }
  &::-webkit-scrollbar {
    background: transparent !important;
  }

  & .custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 0;
  }

  & .row-item {
    flex-wrap: nowrap;
    padding: 1rem;
    &:hover {
      background-color: ${darken("#fff", 0.05)};
    }
  }

  & .row-item-action-darken,
  & .row-item-action {
    padding: 1rem;
    row-gap: 1.25rem;
    & p {
      font-size: 12px;
      color: var(--color-preto);
      margin-bottom: 0;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      &.title {
        font-size: 1rem;
      }
    }
  }

  & .row-item-action-darken {
    background-color: ${darken("#fff", 0.05)};
  }
`;

export const CheckboxAssociacao = styled.input.attrs({ type: "checkbox" })`
  display: none;
  & + label {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  & + label:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(127, 86, 217, 1);
    border-radius: 6px;
    top: 0;
    left: 0;
  }

  &:checked + label:before {
    background-color: transparent;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 12px;
    border: solid rgba(127, 86, 217, 1);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 3px;
    left: 7px;
  }

  &:disabled + label {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:disabled + label:before {
    border-color: rgba(127, 86, 217, 0.5);
  }

  &:disabled:checked + label:after {
    border-color: rgba(127, 86, 217, 0.5);
  }
`;