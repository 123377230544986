import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { MdContentCopy } from 'react-icons/md';
import { RiArrowDownSLine, RiArrowUpSLine, RiErrorWarningLine } from 'react-icons/ri';
import { Collapse, Row, Col, Input, Label, FormGroup } from 'reactstrap'

import * as controller from '../../controllers/inconsistenciaPropostaController'
import { FiRefreshCcw } from 'react-icons/fi';
import { notifySuccess } from '../../components/message/message';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';

export default function NumeroProposta(){
    const { numerosProposta, tab, getAllItems, isFirstPage } = useSelector(state => state.inconsistenciaProposta);

    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        controller.getInconsistenciasPaginado('', pageSelected + 1);
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            controller.getInconsistenciasPaginado(event.target.value, 1);
        }
     }

     useEffect(()=>{
        if(isFirstPage){
            setSelectedPage(0)
        }
     },[isFirstPage])

    return(
        <div style={{width: '93%', marginLeft: '25px'}}>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label>Número proposta</Label>
                        <Input
                            type="text"
                            placeholder="Digite aqui"
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="pt-4">
                <Col md={12}>
                    <Row className="float-right">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            initialPage={0}
                            pageCount={getAllItems.totalPaginas}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={initialPage ? 0 : selectedPage}
                        />
                    </Row>
                </Col>
            </Row>
            {numerosProposta.map((e,i)=>{
                return(
                    <CollapsedCard inconsistencia={e} index={i} tab={tab} page={selectedPage}/>
                )
            })}
        </div>
    )
}

function CollapsedCard({inconsistencia, index, tab, page}){
    const { tabs } = useSelector(state => state.inconsistenciaProposta);

    const [isCardOpen, setIsCardOpen] = useState(false)

    async function handleRefresh(){
        await controller.correcaoInconsistencia(inconsistencia, tab)
        await controller.getInconsistenciasPaginado('', page + 1)
        await controller.getQuantidade(tabs)
    }
    return(
        <div style={{borderTop: '1px solid #CCCFDE', marginBottom: '30px'}}>
            <div style={{display: 'flex', marginTop: '30px'}} >
                <RiErrorWarningLine style={{color: 'var(--color-laranja)',marginRight: '5px', fontSize: '25px'}}/>
                <p style={{fontWeight: 'bold', margin: '5px 75% 0 0'}}>
                {page === '0' ?  `Inconsistência de número proposta ${String(index+1).padStart(2,'0')}` : `Inconsistência de número proposta ${String(index+1 + (Number(page) * 1000)).padStart(2,'0')}`}
                </p>
                <FiRefreshCcw
                style={{paddingTop: '5px', fontSize: '20px', cursor: 'pointer'}} 
                onClick={() => handleRefresh()}/>
                {isCardOpen ? 
                    <RiArrowUpSLine  
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsCardOpen(!isCardOpen)}/> 
                : 
                    <RiArrowDownSLine 
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsCardOpen(!isCardOpen)}/>}
            </div>
            <Collapse isOpen={isCardOpen} style={{paddingTop: '30px', paddingLeft: '5px'}}>
                <Row>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Código</Label>
                            <div style={{display: 'flex', border: '1px solid var(--color-laranja)', borderRadius: '0.25rem', padding: '8px 0 8px 10px', background: '#E9ECEF'}}>
                            <p style={{width: '95%', margin: '0'}}>
                                {inconsistencia.descricao}
                            </p>
                            <CopyToClipboard text={inconsistencia.descricao} onCopy={()=> notifySuccess('Copiado!')}>
                                <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                            </CopyToClipboard>
                            </div>
                    </Col>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Parâmetro</Label>
                        <Input
                            type="text"
                            value={inconsistencia.parametro}
                            disabled
                        />
                    </Col>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Instituição Financeira</Label>
                        <Input
                            type="text"
                            value={inconsistencia.nomeBanco}
                            disabled
                        />
                    </Col>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Nome do arquivo importado</Label>
                        <Input
                            type="text"
                            value={inconsistencia.nomeArquivo}
                            disabled
                        />
                    </Col>
                </Row>
            </Collapse>
        </div>
    )
}