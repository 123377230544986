import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  credenciais: [],
  integracoes: {
    id: '',
    plataforma: '',
    url: '',
    descricao: '',
  },
  plataformas: [
    {
      id: 1,
      descricao: 'WhatsApp Web'
    },
    {
      id: 2,
      descricao: 'Mola Box'
    }
  ],
  tipoIntegracao: [{
    id: '',
    descricao: '',
    tipo: 0
  }],
  parametrosDePara: [],
  item: {
    id: '',
    login: '',
    senha: '',
    url: '',
    plataforma: '',
  },
  id_integracao: '0',
  seeRegister: false,
};

export default function personasParametros(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_INTEGRACAO:
          return {
            ...state,
            id_integracao: action.value,
          };

        case TYPES.SET_SEE:
          return {
            ...state,
            seeRegister: action.value
          }

        case TYPES.CLEAN_REGISTER:
          return {
            ...state,
            seeRegister: INITIAL_STATE.seeRegister
          }

        case TYPES.SET_TIPO_INTEGRACAO:
          return {
            ...state,
            tipoIntegracao: action.value,
          };
        case TYPES.SET_PARAMETROS_DE_PARA:
          return {
            ...state,
            parametrosDePara: action.value,
          };
        case TYPES.SET_ITEM:
          return {
            ...state,
            item: action.value,
          };

        case TYPES.SET_ITEM_FIELD:
          return {
            ...state,
            item: {
              ...state.item, [action.field]: action.value
            }
          };

        case TYPES.CLEAN_ITEM:
          return {
            ...state,
            item: INITIAL_STATE.item
          };

        case TYPES.CLEAN_ALL:
          return {
            ...state,
            item: INITIAL_STATE.item,
            credenciais: INITIAL_STATE.credenciais,
            id_integracao: INITIAL_STATE.id_integracao
          };

        case TYPES.SET_CREDENCIAIS:
          return {
            ...state,
            credenciais: action.value,
          };

        case TYPES.SET_INTEGRACOES:
          return {
            ...state,
            integracoes: action.value,
          };

        case TYPES.SET_CREDENCIAIS_FIELDS:
          {
            const credenciais = state.credenciais
            credenciais[action.index] = {
              ...state.credenciais[action.index],
              [action.field]: action.value,
            }
            return {
              ...state,
              credenciais: credenciais
            };
          }
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
