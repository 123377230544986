import React, { useEffect } from "react";

import { Card, Col, Form, Button } from "reactstrap";

import { IoIosArrowBack } from "react-icons/io";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../controllers/perfilAcessoController";

import Breadcrumb from "../../../components/breadcrumbs";
import SimpleTab from "../../../components/SimpleTab/SimpleTab";
import ModulosLojasTab from "./Register/modulosLojasTab";
import AcoesTab from "./Register/acoesTab";


export function Register() {
  const { perfilDeAcessoBeingCreatedData, activeTab, acoesTelas } = useSelector(
    (state) => state.perfilAcesso
  );

  async function saveItem() {
    const payload = {
      ...perfilDeAcessoBeingCreatedData,
      telasAssociadas: perfilDeAcessoBeingCreatedData.telasAssociadas.map(
        (telaAssociada) => {
          const telaAcoes = acoesTelas.find(
            (acaoTela) =>
              acaoTela.id.toUpperCase() === telaAssociada.id.toUpperCase()
          );
          return {
            ...telaAssociada,
            permissoes: { ...telaAcoes.permissoes },
          };
        }
      ),
    };
    const isSuccessed = await controller.createPerfilDeAcesso(payload);

    if (isSuccessed) {
      actions.setActiveTab("Search");
      const data = await controller.getPerfisDeAcesso();
      actions.setPerfisDeAcesso(data);
    }
  }

  function changeTab(tab) {
    actions.setActiveTab(tab);
  }

  function handleVoltar() {
    changeTab("Search");
  }

  useEffect(() => {
    async function fetchLojasAndTelas() {
      const lojasDisponiveis = await controller.getLojas();
      const telasDisponiveis = await controller.getTelas();

      actions.setLojasDisponiveisInitialValueBackup(lojasDisponiveis);
      actions.setLojasDisponiveis(lojasDisponiveis);
      actions.setTelasDisponiveisInitialValueBackup(telasDisponiveis);
      actions.setTelasDisponiveis(telasDisponiveis);
      actions.setAcoesTelasInitialValueBackup([]);
      actions.setAcoesTelas([]);
    }

    // clear previous data
    actions.setTelasAssociadas([]);
    actions.setLojasAssociadas([]);
    actions.setTelasDisponiveis([]);
    actions.setLojasDisponiveis([]);
    actions.setTelasSelecionadas([]);
    actions.setLojasSelecionadas([]);
    actions.setAcoesTelas([]);
    actions.clearPerfilDeAcessoBeingCreatedData();
    actions.setTelasAssociadasSearchTerm("");
    actions.setTelasDisponiveisSearchTerm("");
    actions.setLojasAssociadasSearchTerm("");
    actions.setLojasDisponiveisSearchTerm("");
    actions.setAcoesTelasSearchTerm("");

    fetchLojasAndTelas();
  }, []);

  return (
    <div className="d-flex flex-column row-gap-15">
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card>
          <Form>
            <Col className="pt-2 pb-2">
              <Button
                onClick={() => {
                  handleVoltar();
                }}
                style={{ width: "150px", color: "white" }}
                className="purpleButton"
              >
                <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                Voltar
              </Button>
              <Button
                onClick={() => saveItem()}
                style={{ width: "242px", float: "right" }}
                className="purpleButton"
              >
                Salvar Cadastro
              </Button>
            </Col>
          </Form>
        </Card>
      </Col>
      <Col>
        <Card className="radius pb-2 px-5">
          <SimpleTab
            tabs={[
              {
                id: 0,
                descricao: "Telas/Lojas",
                component: <ModulosLojasTab />,
              },
              {
                id: 1,
                descricao: "Ações",
                component: <AcoesTab />,
              },
            ]}
          />
        </Card>
      </Col>
    </div>
  );
}
