import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as BsIcons from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
} from "reactstrap";

import { Select } from "../../../components/Inputs/Input";
import { COLUMNS } from "../constants/columns";

import * as actions from "../../../store/modules/aceiteCp/actions";
import * as controller from "../../../controllers/aceiteCpController";

import ModalEditar from "../components/ModalEditar";
import { Checkbox } from "@material-ui/core";
import { maskMoeda } from "../../../util/masks";
import ModalPessoas from "../components/ModalPessoas";
import { notifyWarn } from "../../../components/message/message";
import moment from "moment";

const Aceite = () => {
  const {
    item,
    items,
    canais,
    campanhas,
    hierarquias,
    centrosCusto,
    empresasFuncionario,
    campanhaTipo,
    statusIntegracao,
    enableCheckboxesRule,
    selectedItems,
    allItems,
    statusFavorecido,
    modalEdicao,
    totals,
  } = useSelector((state) => state.aceiteCp);

  const CAMPANHA_COLUNAS = {
    1: [
      "valorProdutividadeOriginal",
      "percPremioPagoProdutividade",
      "contaPagarStatusIntegracaoProdutividade",
    ],
    2: [
      "valorSuperacaoOriginal",
      "percPremioPagoSuperacao",
      "contaPagarStatusIntegracaoSuperacao",
    ],
    3: [
      "valorComissaoPagaOriginal",
      "percComissaoPaga",
      "contaPagarStatusIntegracaoRepasse",
    ],
  };

  const periodos = useMemo(
    () => [
      { id: "1", descricao: "Mês atual" },
      { id: "2", descricao: "Mês anterior" },
      { id: "3", descricao: "90 dias" },
      { id: "4", descricao: "Personalizado" },
    ],
    []
  );

  const getFilteredColumns = (tipoCampanha) => {
    if (!tipoCampanha || tipoCampanha === "0" || tipoCampanha === "") {
      return COLUMNS;
    }

    const colunasPermitidas = CAMPANHA_COLUNAS[tipoCampanha] || [];

    return COLUMNS.filter(
      (col) =>
        colunasPermitidas.includes(col.accessor) ||
        !Object.values(CAMPANHA_COLUNAS).flat().includes(col.accessor)
    );
  };

  const columns = useMemo(
    () => getFilteredColumns(item.campanhaTipo),
    [item.campanhaTipo]
  );

  const data = items;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  const handlePageClick = (e) => {
    const pageSelectedD = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelectedD);
    gotoPage(pageSelectedD);
    const { sortBy } = state;

    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      controller.get(pageSelectedD + 1, pageSize, item, id, desc);
    } else {
      controller.get(pageSelectedD + 1, pageSize, item);
    }
  };

  function handleInitialPage(pageSizeD) {
    setInitialPage(true);
    setSelectedPage(0);
    actions.setSelectedItems([]);
    gotoPage(0);
    controller.get(0 + 1, pageSizeD, item);
  }

  const [modalPessoa, setModalPessoa] = useState(false);
  const togglePessoa = () => setModalPessoa(!modalPessoa);

  const [isModalAceiteOpen, setIsModalAceiteOpen] = useState(false);

  const toggleAceiteModal = () => {
    if (selectedItems.length > 0) {
      setIsModalAceiteOpen(!isModalAceiteOpen);
    } else {
      notifyWarn("Selecione ao menos um item para aceitar.");
    }
  };

  useEffect(() => {
    if (item.canalId !== "0") {
      controller.getCampanhas(item.canalId, item.dataInicio, item.dataFim);
    }
  }, [item.canalId, item.dataInicio, item.dataFim]);

  useEffect(() => {
    if (item.canalId === "0") {
      actions.item("hierarquiaId", "0");
      actions.setHierarquias([]);
    } else {
      controller.getHierarquia(
        item.canalId,
        item.campanhaId !== "0" ? item.campanhaId : null
      );
    }
  }, [item.canalId, item.campanhaId]);

  useEffect(() => {
    if (item.campanhaId !== "0" && item.canalId !== "0") {
      controller.getCentrosCusto(item.canalId, item.campanhaId);
    }
  }, [item.canalId, item.campanhaId]);

  const fieldActions = {
    canalId: () => actions.item("campanhaId", "0"),
    periodoId: (value) => {
      if (value === "0") {
        actions.item("dataInicio", "");
        actions.item("dataFim", "");
        actions.item("campanhaId", "0");
      }
      if (value === "1") {
        const primeiroDiaMes = moment().startOf("month").format("YYYY-MM-DD");
        const ultimoDiaMes = moment().endOf("month").format("YYYY-MM-DD");

        actions.item("dataInicio", primeiroDiaMes);
        actions.item("dataFim", ultimoDiaMes);
        actions.item("campanhaId", "0");
      }
      if (value === "2") {
        const primeiroDiaMesAnterior = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        const ultimoDiaMesAnterior = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");

        actions.item("dataInicio", primeiroDiaMesAnterior);
        actions.item("dataFim", ultimoDiaMesAnterior);
        actions.item("campanhaId", "0");
      }
      if (value === "3") {
        const ultimoDiaMesAnterior = moment()
          .subtract(1, "months")
          .endOf("month");
        const primeiroDiaPeriodo = ultimoDiaMesAnterior
          .clone()
          .subtract(90, "days")
          .startOf("month")
          .format("YYYY-MM-DD");
        const ultimoDiaPeriodo = ultimoDiaMesAnterior.format("YYYY-MM-DD");

        actions.item("dataInicio", primeiroDiaPeriodo);
        actions.item("dataFim", ultimoDiaPeriodo);
        actions.item("campanhaId", "0");
      }
      if (value === "4") {
        actions.item("dataInicio", "");
        actions.item("dataFim", "");
        actions.item("campanhaId", "0");
      }
    },
    dataInicio: () => actions.item("campanhaId", "0"),
    dataFim: () => actions.item("campanhaId", "0"),
  };

  function onChange(field, value) {
    actions.item(field, value);
    if (fieldActions[field]) {
      fieldActions[field](value);
    }
  }

  useEffect(() => {
    controller.getCanais();
    controller.getCentrosCusto();
    controller.getRazaoSocialEmpresa();
    controller.getSistemas();
  }, []);

  useEffect(() => {
    const tipoRegraSelected = {
      "3EF7CAA0-AF73-440C-801B-2B5788E69C37": "valorProdutividadeOriginal",
      "BE21DBC5-6C12-47B0-8C02-3D92111B3379": "valorSuperacaoOriginal",
      "D139F68F-6E22-46E4-B5D4-268D31FC9ECC": "valorComissaoPagaOriginal",
    };

    let soma = 0;

    if (tipoRegraSelected[modalEdicao.tipoRegraId]) {
      soma = selectedItems.reduce((acc, selectedItem) => {
        const pageItem = page.find((item) => item.original.id === selectedItem);
        if (pageItem.original) {
          return (
            acc +
            (Number(
              pageItem.original[tipoRegraSelected[modalEdicao.tipoRegraId]]
            ) || 0)
          );
        }
        return acc;
      }, 0);
    }

    actions.setModalEdicaoField(soma, "valor");
  }, [modalEdicao.tipoRegraId, page, selectedItems]);

  const toggleItemSelection = (id) => {
    let itemsSelecteds = [];
    if (selectedItems.includes(id)) {
      itemsSelecteds = selectedItems.filter((itemId) => itemId !== id);
    } else {
      itemsSelecteds = [...selectedItems, id];
    }
    actions.setSelectedItems(itemsSelecteds);
  };

  const renderTotal = (columnId) => {
    switch (columnId) {
      case "metaTotal":
        return `R$ ${maskMoeda((totals.metaTotal || 0).toFixed(2))}`;
      case "producaoTotal":
        return `R$ ${maskMoeda((totals.producaoTotal || 0).toFixed(2))}`;
      case "totalPago":
        return `R$ ${maskMoeda((totals.totalPago || 0).toFixed(2))}`;
      case "receitaTotal":
        return `R$ ${maskMoeda((totals.receitaTotal || 0).toFixed(2))}`;
      case "valorComissaoPagaOriginal":
        return `R$ ${maskMoeda((totals.repasseTotal || 0).toFixed(2))}`;
      case "valorSuperacaoOriginal":
        return `R$ ${maskMoeda((totals.superacaoTotal || 0).toFixed(2))}`;
      case "valorProdutividadeOriginal":
        return `R$ ${maskMoeda((totals.produtividadeTotal || 0).toFixed(2))}`;
      // Porcentagem
      case "percAtingimentoSobreMeta":
        return `${totals.atingimentoSobreMeta || 0}%`;
      case "percTotalPagoSobreProducao":
        return `${totals.totalPagoSobreProducao || 0}%`;
      case "percTotalPagoSobreReceita":
        return `${totals.totalPagoSobreReceita || 0}%`;
      case "percReceitaTotal":
        return `${totals.receitaTotalPorcentagem || 0}%`;
      case "percComissaoPaga":
        return `${totals.repassePorcentagem || 0}%`;
      case "percPremioPagoSuperacao":
        return `${totals.superacaoPorcentagem || 0}%`;
      case "percPremioPagoProdutividade":
        return `${totals.produtividadePorcentagem || 0}%`;
      default:
        return "";
    }
  };

  return (
    <>
      <Col className="ml-3">
        <Card className="py-5 pr-5 mb-5">
          <Form
            style={{
              margin: "30px 15px",
              border: "1px solid #ccc",
              padding: "15px",
              borderRadius: "7px",
              marginBottom: "10px",
            }}
          >
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Canal:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.canalId }}
                    select={{ value: "id", label: "nome" }}
                    options={canais}
                    onChange={(e) => onChange("canalId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Periodo:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.periodoId }}
                    select={{ value: "id", label: "descricao" }}
                    options={periodos}
                    onChange={(e) => onChange("periodoId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              {item.periodoId === "4" && (
                <>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Data início:</Label>
                      <Input
                        type="date"
                        value={item.dataInicio}
                        onChange={(e) => onChange("dataInicio", e.target.value)}
                        disabled={item.periodoId === "0"}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Data fim:</Label>
                      <Input
                        type="date"
                        value={item.dataFim}
                        onChange={(e) => onChange("dataFim", e.target.value)}
                        disabled={item.periodoId === "0"}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col md={3}>
                <FormGroup>
                  <Label>Campanha:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.campanhaId }}
                    select={{ value: "id", label: "descricao" }}
                    options={campanhas}
                    onChange={(e) => onChange("campanhaId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Hierarquia:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.hierarquiaId }}
                    select={{ value: "hierarquiaId", label: "descricao" }}
                    options={hierarquias}
                    onChange={(e) => onChange("hierarquiaId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label style={{ fontWeight: "700" }}>Persona:</Label>
                  <div className="d-flex flex-grow-1 align-items-center">
                    <Input
                      type="text"
                      placeholder="Busque um consultor..."
                      value={item.pessoa}
                      disabled={item.canalId === "0" || item.campanhaId === "0"}
                      className="flex-grow-1"
                    />
                    <GrFormClose
                      style={{
                        color: "#C4C7CA",
                        fontSize: "20px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        onChange("pessoaId", "0");
                        onChange("pessoa", "");
                      }}
                    />
                    <AiOutlineSearch
                      style={{
                        color: "var(--color-verde)",
                        fontSize: "20px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => togglePessoa()}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Centro de custo:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.centroCustoId }}
                    select={{ value: "id", label: "descricao" }}
                    options={centrosCusto}
                    onChange={(e) => onChange("centroCustoId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Razão social empresa:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.empresaFuncionarioId }}
                    select={{ value: "id", label: "razaoSocialEmpresa" }}
                    options={empresasFuncionario}
                    onChange={(e) => onChange("empresaFuncionarioId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Tipo Campanha:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.campanhaTipo }}
                    select={{ value: "id", label: "descricao" }}
                    options={campanhaTipo}
                    onChange={(e) => onChange("campanhaTipo", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.statusIntegracao }}
                    select={{ value: "id", label: "descricao" }}
                    options={statusIntegracao}
                    onChange={(e) => onChange("statusIntegracao", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label>Data Pagamento:</Label>
                  <Input
                    type="date"
                    value={item.dataPagamento}
                    onChange={(e) => onChange("dataPagamento", e.target.value)}
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Data Integração:</Label>
                  <Input
                    type="date"
                    value={item.dataIntegracao}
                    onChange={(e) => onChange("dataIntegracao", e.target.value)}
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status do Favorecido:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.statusFavorecido }}
                    select={{ value: "id", label: "descricao" }}
                    options={statusFavorecido}
                    onChange={(e) => onChange("statusFavorecido", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="clearButton"
                  onClick={() => {
                    actions.cleanRegister();
                  }}
                >
                  Limpar
                </Button>
                <Button
                  className="purpleButton"
                  style={{ width: "150px", marginRight: "10px" }}
                  onClick={() => {
                    controller.get(1, pageSize, item);
                    gotoPage(0);
                    setInitialPage(true);
                    setSelectedPage(0);
                  }}
                >
                  Buscar
                </Button>
                {item.campanhaId !== "0" && item.campanhaId && (
                  <Button
                    className="clearButton"
                    style={{ width: "150px", marginRight: "10px" }}
                    onClick={() => {
                      controller.downloadPlanilha(
                        item.campanhaId,
                        campanhas.find(
                          (campanha) => campanha.id === item.campanhaId
                        ).descricao
                      );
                    }}
                  >
                    Exportar dados da campanha
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <div
            style={{ overflowX: "auto", marginLeft: "15px", marginTop: "30px" }}
          >
            {items.length === 0 ? (
              <div className="text-center">
                <p>Não existem dados para essa pesquisa</p>
              </div>
            ) : (
              <div
                style={{
                  overflowX: "auto",
                  marginLeft: "15px",
                  marginTop: "30px",
                }}
              >
                <table
                  {...getTableProps()}
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        <th
                          style={{
                            width: "10%",
                            padding: "7px",
                            textAlign: "center",
                          }}
                        >
                          {enableCheckboxesRule && (
                            <Checkbox
                              checked={page
                                .filter(
                                  (row) =>
                                    row.original[enableCheckboxesRule] ===
                                      "5fd35513-c165-40ec-be20-fb1dcf2fc671" ||
                                    row.original[enableCheckboxesRule] ===
                                      "ceca981e-9068-47c5-8735-c9677d2d12f2"
                                )
                                .every((row) =>
                                  selectedItems.includes(row.original.id)
                                )}
                              style={{ color: "green" }}
                              onClick={() => {
                                if (page.length > 0) {
                                  const currentPageIds = page
                                    .filter(
                                      (row) =>
                                        row.original[enableCheckboxesRule] ===
                                          "5fd35513-c165-40ec-be20-fb1dcf2fc671" ||
                                        row.original[enableCheckboxesRule] ===
                                          "ceca981e-9068-47c5-8735-c9677d2d12f2"
                                    )
                                    .map((row) => row.original.id);
                                  let itemsSelecteds = [];
                                  const newSelectedItems = selectedItems.filter(
                                    (itemId) => !currentPageIds.includes(itemId)
                                  );
                                  itemsSelecteds =
                                    newSelectedItems.length !==
                                    selectedItems.length
                                      ? newSelectedItems
                                      : [...selectedItems, ...currentPageIds];
                                  actions.setSelectedItems(itemsSelecteds);
                                }
                              }}
                            />
                          )}
                        </th>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: "250px",
                              padding: "1px",
                              textAlign: "left",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ marginRight: "1px" }}>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <BsIcons.BsArrowDown />
                                  ) : (
                                    <BsIcons.BsArrowUp />
                                  )
                                ) : (
                                  <BsIcons.BsArrowDownUp />
                                )}
                              </span>
                              <span>{column.render("Header")}</span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    style={{ verticalAlign: "top" }}
                  >
                    {page.map((row) => {
                      prepareRow(row);
                      const isSelected = selectedItems.includes(
                        row.original.id
                      );
                      return (
                        <tr
                          {...row.getRowProps()}
                          style={{
                            borderBottom: "1px solid #ddd",
                            backgroundColor: isSelected ? "#d0d3d3fc" : "",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const updatedSelectedItems = isSelected
                              ? selectedItems.filter(
                                  (id) => id !== row.original.id
                                )
                              : [...selectedItems, row.original.id];
                            actions.setSelectedItems(updatedSelectedItems);
                          }}
                        >
                          <td style={{ textAlign: "center", padding: "7px" }}>
                            {enableCheckboxesRule &&
                              (row.original[enableCheckboxesRule] ===
                                "5fd35513-c165-40ec-be20-fb1dcf2fc671" ||
                                row.original[enableCheckboxesRule] ===
                                  "ceca981e-9068-47c5-8735-c9677d2d12f2") && (
                                <Checkbox
                                  checked={selectedItems.includes(
                                    row.original.id
                                  )}
                                  style={{ color: "green" }}
                                  size={"small"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleItemSelection(row.original.id);
                                  }}
                                />
                              )}
                          </td>
                          {row.cells.map((cell) => (
                            <td
                              {...cell.getCellProps()}
                              style={{ padding: "15px", textAlign: "left" }}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td className="tfoot-checkbox"></td>
                      {columns.map((column) => (
                        <td
                          key={column.accessor}
                          className={`tfoot-cell ${
                            column.align === "right"
                              ? "align-right"
                              : "align-left"
                          }`}
                        >
                          {renderTotal(column.accessor)}
                        </td>
                      ))}
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>

          <Row className="pt-4">
            <Col>
              <span style={{ color: "var(--color-cinza-medio)" }}>
                Mostrando{" "}
                {allItems.quantidade !== undefined ? allItems.quantidade : "0"}{" "}
                de{" "}
                {allItems.quantidadeTotal !== undefined
                  ? allItems.quantidadeTotal
                  : "0"}
              </span>
            </Col>
            <Col md={8}>
              <Row className="float-right">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  initialPage={0}
                  pageCount={allItems.totalPaginas}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={initialPage ? 0 : selectedPage}
                />
                <Input
                  type="select"
                  className="pageSize"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    handleInitialPage(Number(e.target.value));
                  }}
                >
                  {[10, 25, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Input>
              </Row>
            </Col>
          </Row>
          <Col>
            {items.length > 0 && (
              <Button
                onClick={() => toggleAceiteModal()}
                style={{ width: "232px", float: "right", marginLeft: "auto" }}
                className="purpleButton"
              >
                Aceitar itens selecionados
              </Button>
            )}
          </Col>
        </Card>
      </Col>
      {modalPessoa && (
        <ModalPessoas
          isOpen={modalPessoa}
          toggle={togglePessoa}
          isSearch={false}
        />
      )}
      {isModalAceiteOpen && (
        <ModalEditar
          isOpen={isModalAceiteOpen}
          toggle={toggleAceiteModal}
          pagination={{
            page: selectedPage,
            pageSize: pageSize,
          }}
        />
      )}
    </>
  );
};

export default Aceite;
