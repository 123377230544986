import api from "../../util/api";
import * as parametrosActions from "../../store/modules/personas/parametros_store/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { notifySuccess, notifyWarn } from "../../components/message/message";

export async function getTipoIntegracao() {
  const data = await api.get("TipoIntegracao");

  if (!data.success) {
    return;
  }

  parametrosActions.setItemTipoIntegracao(data.content);
}

export async function getParametrosDePara() {
  const data = await api.get("ParametrosDePara");

  if (!data.success) {
    return;
  }

  parametrosActions.setParametrosDePara(
    data.content.map((item) => {
      return {
        id: item.id,
        descricao: item.variavel,
        campoEquivalente: item.campoEquivalenteCrm,
      };
    })
  );
}

export async function getCredencial(lojaId) {
  const data = await api.get(`CredenciaisBmg/${lojaId}`);

  if (!data.success) {
    return;
  }

  parametrosActions.setCredenciais([
    {
      id: data.content.id,
      login: data.content.login,
      senha: "",
    },
  ]);
}

export async function deleteCredencial(id) {
  setLoading(true);
  const data = await api.delete(`CredenciaisBmg/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  parametrosActions.cleanAll();
  return true;
}

export async function updateCredencial(item) {
  if (item.login === "") {
    return notifyWarn("Necessário informar o login.");
  }

  if (item.senha === "") {
    return notifyWarn("Necessário informar a senha.");
  }

  const body = {
    login: item.login,
    senha: item.senha,
  };

  setLoading(true);
  const data = await api.put(`CredenciaisBmg/${item.id}`, body);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Credencial atualizada com sucesso!");
}

export async function createCredencial(item, lojaId) {
  if (item.login === "") {
    return notifyWarn("Necessário informar o login.");
  }

  if (item.senha === "") {
    return notifyWarn("Necessário informar a senha.");
  }

  const body = {
    login: item.login,
    senha: item.senha,
    lojaId: lojaId,
  };

  setLoading(true);
  const data = await api.post(`CredenciaisBmg/`, body);
  setLoading(false);

  if (!data.success) {
    return;
  }

  getCredencial(lojaId);

  notifySuccess("Credencial adicionada com sucesso! ");
}

export async function salveParametroIntegracao(
  item,
  tipoIntegracao,
  tipoLoja,
  update
) {
  const body = {
    Plataforma: item.plataforma === 1 ? "WhatsApp Web" : "MolaBox",
    Url: item.url,
    TipoIntegracaoId: tipoIntegracao,
    LojaId: tipoLoja.id,
  };

  setLoading(true);
  const data = await api.post(`ParametroIntegracao/parametrosIntegracao`, body);
  setLoading(false);
  if (!data.success) {
    return;
  }
  notifySuccess("Parametro integração adicionado com sucesso! ");
}

export async function updateParametroIntegracao(
  item,
  tipoIntegracaoId,
  tipoLoja
) {
  const body = {
    plataforma: item.plataforma === 1 ? "WhatsApp Web" : "MolaBox",
    tipoIntegracaoId: tipoIntegracaoId,
    lojaID: tipoLoja.id,
    url: item.url,
  };
  const data = await api.post(`ParametroIntegracao/${item.id}/editar`, body);

  if (!data.success) {
    return;
  }
  notifySuccess("Parametro integração atualizado com sucesso! ");
}

export async function GetByLojaId(tipoLojaId) {
  const data = await api.get(`ParametroIntegracao/${tipoLojaId}`);

  if (!data.success) {
    return;
  }

  if (data.content) {
    const integracao = {
      id: data.content.id,
      plataforma: data.content.plataforma === "WhatsApp Web" ? 1 : 2,
      url: data.content.url,
      descricao: data.content.tipoIntegracao.descricao,
    };
    parametrosActions.setIntegracoes(integracao);
    parametrosActions.setItemField("id", data.content.id);
  } else {
    parametrosActions.setIntegracoes({
      id: "",
      plataforma: "",
      url: "",
    });
    parametrosActions.setItemField("id", "");
  }
}

export async function deleteIntegracao(id, tipoLojaId) {
  setLoading(true);
  const data = await api.delete(`ParametroIntegracao/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }
  parametrosActions.cleanAll();
  GetByLojaId(tipoLojaId);
  return true;
}
