import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as actions from "../../../../store/modules/perfilAcesso/actions";
import { Col, Label, Row } from "reactstrap";
import { BiSearchAlt } from "react-icons/bi";
import { AssociacaoContainer, CheckboxAssociacao } from "../../style";
import LoadingIcon from "../../../../components/Loading/LoadingIcon";
import { telasSistema } from "../../../../util/telas";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

export const tiposOperacaoSistema = [
  {
    option: "visualizar",
    descricao: "Visualizar",
    id: "23B1EA3F-AF52-4F1D-B891-27CCDC2D025D",
  },
  {
    option: "criar",
    descricao: "Criar",
    id: "2A4A690A-01D2-4364-ABA4-6B2D977A3442",
  },
  {
    option: "deletar",
    descricao: "Deletar",
    id: "A02B98DB-5DD3-4803-91B6-CDE757D86C00",
  },
  {
    option: "estornar",
    descricao: "Estornar",
    id: "1702FE06-7E33-48EE-BE6C-0B96B5209ACA",
  },
  {
    option: "autorizar",
    descricao: "Autorizar",
    id: "D4BB230A-9583-4A04-9583-1A8B5D14F750",
  },
  {
    option: "filtros",
    descricao: "Filtros",
    id: "4EC5FA97-45ED-4659-901A-F50DCC4F5575",
  },
  {
    option: "desbloquear",
    descricao: "Desbloquear",
    id: "CDDB1F17-A507-461C-A004-5B040EA330C9",
  },
  {
    option: "exportar",
    descricao: "Exportar",
    id: "9C29FF4B-6305-47F7-83EA-0168CF7C88E4",
  },
  {
    option: "visualizarTabelaCanal",
    descricao: "Visualizar Tabela Canal",
    id: "389E140D-9DC0-4E66-933C-A4C6BCDC2E78",
  },
];

const Acoes = () => {
  const {
    acoesTelas,
    acoesSelecionadas,
    activeTab,
    acoesTelasSearchTerm,
    acoesTelasSearchResults,

    loadingTelas,
    telasAssociadas,
  } = useSelector((state) => state.perfilAcesso);

  const telas =
    acoesTelasSearchTerm?.length > 0 ? acoesTelasSearchResults : acoesTelas;

  const isAllSearchResultsSelected =
    acoesTelasSearchResults?.length > 0 &&
    acoesTelasSearchResults.every((telaSearchResult) => {
      const tela = acoesTelas.find((t) => t.id === telaSearchResult.id);
      return (
        tela &&
        Object.values(tela.permissoes).every((permissao) => permissao === true)
      );
    });

  const isAllSelected =
    acoesTelasSearchTerm?.length > 0
      ? isAllSearchResultsSelected
      : acoesTelas.every((tela) =>
          Object.values(tela.permissoes).every(
            (permissao) => permissao === true
          )
        );

  const isTelasEmpty = acoesTelas.filter((tela) => Object.keys(tela.permissoes).length > 0)?.length === 0;

  const isSelectAllVisible =
    acoesTelasSearchTerm?.length > 0
      ? acoesTelasSearchResults?.length > 0
      : !(isTelasEmpty || activeTab === "Visualize");

  function handleSearchAcoesTelas(inputValue) {
    actions.setAcoesTelasSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setAcoesTelasSearchTerm("");
      actions.setAcoesTelasSearchResults([]);
      actions.setAcoesTelasSelectedSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();

    const telasFiltradas = acoesTelas.filter((tela) =>
      tela.nomeTela.toLowerCase().includes(searchTermLowercase)
    );

    actions.setAcoesTelasSearchResults(telasFiltradas);

    actions.setAcoesTelasSelectedSearchResults(
      telasFiltradas.filter((telaDisponivel) =>
        acoesSelecionadas?.some(
          (telaSelecionada) => telaSelecionada.id === telaDisponivel.id
        )
      )
    );
  }

  function handleSelectAllAcoesTelas() {
    actions.setAllAcoesTelas(!isAllSelected);
  }

  useEffect(() => {
    const telasFiltradas = telasSistema.filter((telaSistema) =>
      telasAssociadas.some(
        (telaAssociada) =>
          telaAssociada.id.toUpperCase() === telaSistema.id.toUpperCase()
      )
    );

    const acoesTelasAtualizadas = telasFiltradas.map((tela) => {
      const telaExistente = acoesTelas.find(
        (t) => t.id.toUpperCase() === tela.id.toUpperCase()
      );
      const permissoesIniciais = tiposOperacaoSistema
        .filter((tipoOperacao) => tela.options[tipoOperacao.option] === true)
        .reduce((acc, tipoOperacao) => {
          acc[tipoOperacao.option] = telaExistente
            ? telaExistente.permissoes[tipoOperacao.option] || false
            : false;
          return acc;
        }, {});

      return {
        id: tela.id,
        nomeTela: tela.nomeTela,
        permissoes: permissoesIniciais,
      };
    });

    actions.setAcoesTelas(acoesTelasAtualizadas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telasAssociadas]);

  return (
    <Col md={6}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Ações
      </Label>
      <OutlinedInput
        className="input-default-grey mb-2"
        placeholder="Buscar"
        value={acoesTelasSearchTerm}
        onChange={(e) => {
          handleSearchAcoesTelas(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <BiSearchAlt></BiSearchAlt>
          </InputAdornment>
        }
      />
      <AssociacaoContainer>
        {isSelectAllVisible ? (
          <div className="container-fluid">
            <Row className="row-item-action">
              <Col className="d-flex align-items-center">
                <label className="custom-checkbox">
                  <CheckboxAssociacao
                    id="acoes-telas-selectAll"
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={() => handleSelectAllAcoesTelas()}
                    disabled={isTelasEmpty || activeTab === "Visualize"}
                  />
                  <label htmlFor="acoes-telas-selectAll"></label>
                </label>
                <p
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Selecionar todos
                </p>
              </Col>
            </Row>
          </div>
        ) : null}
        {loadingTelas && <LoadingIcon color="var(--color-roxo)" />}
        {telas
          .filter((tela) => Object.keys(tela.permissoes).length > 0)
          ?.sort((a, b) => a.nomeTela.localeCompare(b.nomeTela))
          .map((tela, i) => {
            return (
              <div key={tela.id} className="container-fluid">
                <Row className="row-item-action-darken">
                  <Col className="d-flex align-items-center justify-content-center">
                    <p className="title">{tela.nomeTela}</p>
                  </Col>
                </Row>
                <Row className="row-item-action">
                  {tiposOperacaoSistema
                    .filter((tipoOperacao) =>
                      telasSistema?.some(
                        (telaSistema) =>
                          telaSistema.id.toLocaleUpperCase() ===
                            tela.id.toLocaleUpperCase() &&
                          telaSistema.options[tipoOperacao.option] === true
                      )
                    )
                    .map((tipoOperacao) => {
                      const checked = acoesTelas.some(
                        (acaoTela) =>
                          acaoTela.id.toLocaleUpperCase() ===
                            tela.id.toLocaleUpperCase() &&
                          acaoTela.permissoes[tipoOperacao.option] === true
                      );
                      return (
                        <Col
                          md={4}
                          className="d-flex align-items-center"
                          key={`col-${tela.id}-${tipoOperacao.id}`}
                        >
                          <label className="custom-checkbox">
                            <CheckboxAssociacao
                              id={`acao-disponivel-${tela.id}-${tipoOperacao.id}`}
                              type="checkbox"
                              checked={checked}
                              onChange={() =>
                                actions.setAcaoTela(
                                  !checked,
                                  tela.id,
                                  tipoOperacao.option
                                )
                              }
                              disabled={activeTab === "Visualize"}
                            />
                            <label
                              htmlFor={`acao-disponivel-${tela.id}-${tipoOperacao.id}`}
                            ></label>
                          </label>
                          <p
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            {tipoOperacao.descricao}
                          </p>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            );
          })}
      </AssociacaoContainer>
    </Col>
  );
};

export default Acoes;
