import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
} from "reactstrap";
import classnames from "classnames";
import Informacoes from "./panels/informacoes/Informacoes";
import Endereco from "./panels/endereco/endereco";
import Contato from "./panels/contato/contato";
import Documentacao from "./panels/documentacao/documentacao";
import Papeis from "./panels/papeis/papeis";
import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/personas/personas_store/actions";
import ContaCorrente from "./panels/contaCorrente/contaCorrente";
import { notifyWarn } from "../../../components/message/message";
import Parametros from "./panels/parametros/parametros";

const Register = () => {
  const { tab } = useSelector((state) => state.personas);
  const { informacao } = useSelector((state) => state.personasInformacoes);
  const { tipoLoja } = useSelector((state) => state.personasPapeis);

  function changeTab(value) {
    if (informacao.id !== null) {
      actions.setTab(value);
    } else {
      notifyWarn("É necessário salvar as informações antes de continuar");
    }
  }

  return (
    <Col>
      <Card className="pt-3 pb-2 mb-5">
        <div>
          <Nav style={{ marginLeft: "25px" }}>
            <NavItem style={{ padding: "0 5px 0 5px" }}>
              <NavLink
                className={classnames({ active: tab === "1" })}
                onClick={() => {
                  changeTab("1");
                }}
                style={
                  tab === "1"
                    ? {
                        color: "var(--color-preto",
                        fontWeight: "700",
                        borderBottom: "3px solid var(--color-verde-claro)",
                        transition: "0.1s",
                        cursor: "pointer",
                      }
                    : {
                        color: "var(--color-preto",
                        fontWeight: "700",
                        cursor: "pointer",
                      }
                }
              >
                Informações
              </NavLink>
            </NavItem>
            <NavItem style={{ padding: "0 15px 0 15px" }}>
              <NavLink
                className={classnames({ active: tab === "2" })}
                onClick={() => {
                  changeTab("2");
                }}
                style={
                  tab === "2"
                    ? {
                        color: "var(--color-preto",
                        fontWeight: "700",
                        cursor: "pointer",
                        borderBottom: "3px solid var(--color-verde-claro)",
                        transition: "0.1s",
                      }
                    : {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                      }
                }
              >
                Relacionamento
              </NavLink>
            </NavItem>
            <NavItem style={{ padding: "0 15px 0 15px" }}>
              <NavLink
                className={classnames({ active: tab === "3" })}
                onClick={() => {
                  changeTab("3");
                }}
                style={
                  tab === "3"
                    ? {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                        borderBottom: "3px solid var(--color-verde-claro)",
                        transition: "0.1s",
                      }
                    : {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                      }
                }
              >
                Endereço
              </NavLink>
            </NavItem>
            <NavItem style={{ padding: "0 15px 0 15px" }}>
              <NavLink
                className={classnames({ active: tab === "4" })}
                onClick={() => {
                  changeTab("4");
                }}
                style={
                  tab === "4"
                    ? {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                        borderBottom: "3px solid var(--color-verde-claro)",
                        transition: "0.1s",
                      }
                    : {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                      }
                }
              >
                Contato
              </NavLink>
            </NavItem>
            <NavItem style={{ padding: "0 15px 0 15px" }}>
              <NavLink
                className={classnames({ active: tab === "5" })}
                onClick={() => {
                  changeTab("5");
                }}
                style={
                  tab === "5"
                    ? {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                        borderBottom: "3px solid var(--color-verde-claro)",
                        transition: "0.1s",
                      }
                    : {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                      }
                }
              >
                Documentação
              </NavLink>
            </NavItem>
            <NavItem style={{ padding: "0 15px 0 15px" }}>
              <NavLink
                className={classnames({ active: tab === "6" })}
                onClick={() => {
                  changeTab("6");
                }}
                style={
                  tab === "6"
                    ? {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                        borderBottom: "3px solid var(--color-verde-claro)",
                        transition: "0.1s",
                      }
                    : {
                        color: "var(--color-preto",
                        cursor: "pointer",
                        fontWeight: "700",
                      }
                }
              >
                Dados Bancários
              </NavLink>
            </NavItem>
            {tipoLoja.id && (
              <NavItem style={{ padding: "0 15px 0 15px" }}>
                <NavLink
                  className={classnames({ active: tab === "7" })}
                  onClick={() => {
                    changeTab("7");
                  }}
                  style={
                    tab === "7"
                      ? {
                          color: "var(--color-preto",
                          cursor: "pointer",
                          fontWeight: "700",
                          borderBottom: "3px solid var(--color-verde-claro)",
                          transition: "0.1s",
                        }
                      : {
                          color: "var(--color-preto",
                          cursor: "pointer",
                          fontWeight: "700",
                        }
                  }
                >
                  Parâmetros
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId="1">
              <Informacoes />
            </TabPane>
            <TabPane tabId="2">
              <Papeis />
            </TabPane>
            <TabPane tabId="3">
              <Endereco />
            </TabPane>
            <TabPane tabId="4">
              <Contato />
            </TabPane>
            <TabPane tabId="5">
              <Documentacao />
            </TabPane>
            <TabPane tabId="6">
              <ContaCorrente />
            </TabPane>
            {tipoLoja.id && (
              <TabPane tabId="7">
                <Parametros />
              </TabPane>
            )}
          </TabContent>
        </div>
      </Card>
    </Col>
  );
};

export default Register;
