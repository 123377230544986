import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Select } from "../../../../../..//components/Inputs/Input";
import * as actions from "../../../../../../store/modules/personas/parametros_store/actions";
import { AiOutlinePlusCircle } from "react-icons/ai";
import * as parametrosController from "../../../../../../controllers/personasController/parametrosController";
import TableCredencial from "./TableCredencial";
import TableParametro from "./TableParametro";
import InputVariable from "../../../../../../components/InputVariable/Input";

const SeletorIntegracao = ({ id_integracao }) => {
  const {
    credenciais,
    item,
    seeRegister,
    plataformas,
    integracoes,
    parametrosDePara,
  } = useSelector((state) => state.personasParametros);
  const { tipoLoja } = useSelector((state) => state.personasPapeis);

  async function addCredencial() {
    if (item.id !== "") {
      await parametrosController.updateCredencial(item);
      parametrosController.getCredencial(tipoLoja.id);
    } else {
      parametrosController.createCredencial(item, tipoLoja.id);
    }

    actions.cleanItem();
  }

  switch (id_integracao) {
    case "0":
      return (
        <Card
          className="p-3 m-4"
          style={{ backgroundColor: "var(--color-quase-branco)" }}
        >
          <Row>
            <Col md={4}>
              Escolha o tipo de integração para cadastrar os parâmetros
            </Col>
          </Row>
        </Card>
      );
    case "62f29e1b-7fd2-42b1-be17-7c716ab361bd":
      return (
        <>
          <Card
            className="p-3 m-4"
            style={{ backgroundColor: "var(--color-quase-branco)" }}
          >
            <Col>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="loginCredencial">Login</Label>
                    <Input
                      value={item.login}
                      disabled={seeRegister}
                      onChange={(e) =>
                        actions.setItemField("login", e.target.value)
                      }
                      id="loginCredencial"
                      placeholder="Digite aqui..."
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="senhaCredencial">Senha</Label>
                    <Input
                      value={item.senha}
                      disabled={seeRegister}
                      onChange={(e) =>
                        actions.setItemField("senha", e.target.value)
                      }
                      id="senhaCredencial"
                      type="password"
                      placeholder="Digite aqui..."
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {!seeRegister &&
                  (!credenciais.length > 0 || item.id !== "") && (
                    <Col>
                      <Label className="mt-2">
                        <AiOutlinePlusCircle
                          style={{
                            fontSize: "1.5rem",
                            marginRight: "7px",
                            color: "green",
                            cursor: "pointer",
                          }}
                          onClick={() => addCredencial()}
                        />
                        Adicionar Credencial
                      </Label>
                    </Col>
                  )}
              </Row>
            </Col>
          </Card>
          {credenciais.length > 0 && (
            <TableCredencial credencial={credenciais} />
          )}
        </>
      );
    case 'dcc7d5ea-8b74-43df-9a32-061373b1308e':
      return (
        <>
          <Card
            className="p-3 m-4"
            style={{ backgroundColor: "var(--color-quase-branco)" }}
          >
            <Col>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="plataformaSelect">Plataforma</Label>
                    <Select
                      style={{ margin: "25px 0 0 25px" }}
                      first="Selecione"
                      value={{ value: item.plataforma }}
                      onChange={(e) =>
                        actions.setItemField("plataforma", e.value)
                      }
                      select={{ value: "id", label: "descricao" }}
                      options={plataformas}
                      isSearchable={false}
                      id="plataformaSelect"
                    />
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label for="urlIntegracao">URL</Label>
                    <InputVariable
                      rawValue={item.url}
                      onRawChange={(value) =>
                        actions.setItemField("url", value)
                      }
                      type="url"
                      placeholder="Digite aqui..."
                      variables={parametrosDePara}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Card>
          {integracoes.id && (
            <TableParametro integracao={integracoes} isIntegracao={true} />
          )}
        </>
      );
    case "f27e6218-9c1d-4018-b0d7-17f814c34c73":
      return (
        <>
          <Card
            className="p-3 m-4"
            style={{ backgroundColor: "var(--color-quase-branco)" }}
          >
            <Col>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="plataformaSelect">Plataforma</Label>
                    <Select
                      style={{ margin: "25px 0 0 25px" }}
                      first="Selecione"
                      value={{ value: item.plataforma }}
                      onChange={(e) =>
                        actions.setItemField("plataforma", e.value)
                      }
                      select={{ value: "id", label: "descricao" }}
                      options={plataformas}
                      isSearchable={false}
                      id="plataformaSelect"
                    />
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label for="urlIntegracao">URL</Label>
                    <InputVariable
                      rawValue={item.url}
                      onRawChange={(value) =>
                        actions.setItemField("url", value)
                      }
                      type="url"
                      placeholder="Digite aqui..."
                      variables={parametrosDePara}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Card>
          {integracoes.id && (
            <TableParametro integracao={integracoes} isIntegracao={true} />
          )}
        </>
      );
    default:
      return (
        <Card
          className="p-3 m-4"
          style={{ backgroundColor: "var(--color-quase-branco)" }}
        >
          <Row>
            <Col md={4}>Integração não parametrizada</Col>
          </Row>
        </Card>
      );
  }
};

export default SeletorIntegracao;
