import api from "../util/api";
import * as actions from "../store/modules/perfilAcesso/actions";
import { notifyWarn, notifySuccess } from "../components/message/message";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function getPerfisDeAcesso() {
  setLoading(true);
  const data = await api.get(`GrupoAcesso`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function getTelas() {
  actions.setLoadingTelas(true);
  const data = await api.get(`telaSistema`);
  actions.setLoadingTelas(false);

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function getLojas() {
  actions.setLoadingLojas(true);
  const data = await api.get(`lojas`);
  actions.setLoadingLojas(false);

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function getCanais() {
  setLoading(true);
  const data = await api.get(`Canal`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function getLojasByCanal(canalId) {
  actions.setLoadingLojas(true);
  const data = await api.get(`lojas/canal/${canalId}`);
  actions.setLoadingLojas(false);

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function createPerfilDeAcesso(registro) {
  if (registro.descricao === "") {
    return notifyWarn("Descrição não pode estar em branco");
  }

  let permissoesTelas = [];
  registro.telasAssociadas.forEach((tela) => {
    if (tela.permissoes.criar) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "2A4A690A-01D2-4364-ABA4-6B2D977A3442",
      });
    }
    if (tela.permissoes.deletar) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "A02B98DB-5DD3-4803-91B6-CDE757D86C00",
      });
    }
    if (tela.permissoes.estornar) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "1702FE06-7E33-48EE-BE6C-0B96B5209ACA",
      });
    }
    if (tela.permissoes.autorizar) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "D4BB230A-9583-4A04-9583-1A8B5D14F750",
      });
    }
    if (tela.permissoes.filtros) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "4EC5FA97-45ED-4659-901A-F50DCC4F5575",
      });
    }
    if (tela.permissoes.desbloquear) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "CDDB1F17-A507-461C-A004-5B040EA330C9",
      });
    }
    if (
      !tela.permissoes.criar &&
      !tela.permissoes.deletar &&
      !tela.permissoes.estornar &&
      !tela.permissoes.autorizar &&
      !tela.permissoes.filtros &&
      !tela.permissoes.desbloquear
    ) {
      permissoesTelas.push({
        telaSistemaId: tela.id,
        tipoOperacaoSistemaId: "23B1EA3F-AF52-4F1D-B891-27CCDC2D025D",
      });
    }
  });

  const lojas = registro.lojasAssociadas.map((loja) => {
    return {
      lojaId: loja.id,
    };
  });

  let body = {};

  if (registro.isHierarquia) {
    body = {
      descricao: registro.descricao,
      administrativo: registro.isAdministrativo,
      hierarquico: registro.isHierarquia,
      permissoesTela: permissoesTelas,
    };
  } else {
    body = {
      descricao: registro.descricao,
      administrativo: registro.isAdministrativo,
      hierarquico: registro.isHierarquia,
      permissoesTela: permissoesTelas,
      lojas: lojas,
    };
  }

  setLoading(true);
  const data = await api.post("GrupoAcesso", body);
  setLoading(false);

  if (!data.success) {
    notifyWarn("Não foi possível salvar o registro");
    return false;
  }

  notifySuccess("Permissão de acesso cadastrada");
  return true;
}

export async function updatePerfilDeAcesso(registro) {
  if (registro.descricao === "") {
    return notifyWarn("Descrição não pode estar em branco");
  }

  let permissoesTelasAdicionar = [];
  let permissoesTelasRemover = [];
  registro.telasAssociadas.forEach((tela) => {
    if(tela.permissoes) {
      if (tela.permissoes.criar) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "2A4A690A-01D2-4364-ABA4-6B2D977A3442",
        });
      }
      if (tela.permissoes.deletar) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "A02B98DB-5DD3-4803-91B6-CDE757D86C00",
        });
      }
      if (tela.permissoes.estornar) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "1702FE06-7E33-48EE-BE6C-0B96B5209ACA",
        });
      }
      if (tela.permissoes.autorizar) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "D4BB230A-9583-4A04-9583-1A8B5D14F750",
        });
      }
      if (tela.permissoes.filtros) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "4EC5FA97-45ED-4659-901A-F50DCC4F5575",
        });
      }
      if (tela.permissoes.desbloquear) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "CDDB1F17-A507-461C-A004-5B040EA330C9",
        });
      }
      if (tela.permissoes.exportar) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "9C29FF4B-6305-47F7-83EA-0168CF7C88E4",
        });
      }
      if (tela.permissoes.tabelaCanal) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "389E140D-9DC0-4E66-933C-A4C6BCDC2E78",
        });
      }
      if (
        !tela.permissoes.criar &&
        !tela.permissoes.deletar &&
        !tela.permissoes.estornar &&
        !tela.permissoes.autorizar &&
        !tela.permissoes.filtros &&
        !tela.permissoes.desbloquear
      ) {
        permissoesTelasAdicionar.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "23B1EA3F-AF52-4F1D-B891-27CCDC2D025D",
        });
      }
    }

    if(tela.permissoesTelasRemover) {
      if (tela.permissoesTelasRemover.visualizar === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "23B1EA3F-AF52-4F1D-B891-27CCDC2D025D",
        });
      }
      if (tela.permissoesTelasRemover.criar === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "2A4A690A-01D2-4364-ABA4-6B2D977A3442",
        });
      }
      if (tela.permissoesTelasRemover.deletar === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "A02B98DB-5DD3-4803-91B6-CDE757D86C00",
        });
      }
      if (tela.permissoesTelasRemover.estornar === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "1702FE06-7E33-48EE-BE6C-0B96B5209ACA",
        });
      }
      if (tela.permissoesTelasRemover.autorizar === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "D4BB230A-9583-4A04-9583-1A8B5D14F750",
        });
      }
      if (tela.permissoesTelasRemover.filtros === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "4EC5FA97-45ED-4659-901A-F50DCC4F5575",
        });
      }
      if (tela.permissoesTelasRemover.desbloquear === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "CDDB1F17-A507-461C-A004-5B040EA330C9",
        });
      }
      if (tela.permissoesTelasRemover.exportar === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "9C29FF4B-6305-47F7-83EA-0168CF7C88E4",
        });
      }
      if (tela.permissoesTelasRemover.tabelaCanal === false) {
        permissoesTelasRemover.push({
          telaSistemaId: tela.id,
          tipoOperacaoSistemaId: "389E140D-9DC0-4E66-933C-A4C6BCDC2E78",
        });
      }
    }
  });

  let body = {};

  body = {
    descricao: registro.descricao,
    administrativo: registro.isAdministrativo,
    permissoesAdicionar: permissoesTelasAdicionar,
    permissoesRemover: permissoesTelasRemover,
  };

  setLoading(true);
  const data = await api.put(`grupoAcesso/${registro.id}`, body);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Perfil de Acesso atualizado com sucesso");
  return true;
}

export async function addTelaAssociadaPermission({
  perfilDeAcessoId,
  tela,
  tipoOperacao,
}) {
  var body = {};
  if (tipoOperacao === "criar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "2A4A690A-01D2-4364-ABA4-6B2D977A3442",
    };
  }
  if (tipoOperacao === "deletar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "A02B98DB-5DD3-4803-91B6-CDE757D86C00",
    };
  }
  if (tipoOperacao === "autorizar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "D4BB230A-9583-4A04-9583-1A8B5D14F750",
    };
  }
  if (tipoOperacao === "filtros") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "4EC5FA97-45ED-4659-901A-F50DCC4F5575",
    };
  }
  if (tipoOperacao === "estornar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "1702FE06-7E33-48EE-BE6C-0B96B5209ACA",
    };
  }
  if (tipoOperacao === "visualizar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "23B1EA3F-AF52-4F1D-B891-27CCDC2D025D",
    };
  }
  if (tipoOperacao === "desbloquear") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "CDDB1F17-A507-461C-A004-5B040EA330C9",
    };
  }

  setLoading(true);
  const data = await api.put(
    `grupoAcesso/${perfilDeAcessoId}/vinculo-telas`,
    body
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }

  return true;
}

export async function addAllTelas({ perfilDeAcessoId }) {
  setLoading(true);
  const data = await api.put(
    `grupoAcesso/${perfilDeAcessoId}/vinculo-todas-telas`
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }

  return true;
}

export async function removeTelaAssociadaPermission({
  perfilDeAcessoId,
  tela,
  tipoOperacao,
}) {
  var body = {};
  if (tipoOperacao === "criar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "2A4A690A-01D2-4364-ABA4-6B2D977A3442",
    };
  }
  if (tipoOperacao === "deletar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "A02B98DB-5DD3-4803-91B6-CDE757D86C00",
    };
  }
  if (tipoOperacao === "autorizar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "D4BB230A-9583-4A04-9583-1A8B5D14F750",
    };
  }
  if (tipoOperacao === "filtros") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "4EC5FA97-45ED-4659-901A-F50DCC4F5575",
    };
  }
  if (tipoOperacao === "estornar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "1702FE06-7E33-48EE-BE6C-0B96B5209ACA",
    };
  }
  if (tipoOperacao === "visualizar") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "23B1EA3F-AF52-4F1D-B891-27CCDC2D025D",
    };
  }
  if (tipoOperacao === "desbloquear") {
    body = {
      telaSistemaId: tela.id,
      tipoOperacaoSistemaId: "CDDB1F17-A507-461C-A004-5B040EA330C9",
    };
  }

  setLoading(true);
  const data = await api.put(
    `grupoAcesso/${perfilDeAcessoId}/remover-vinculo-telas`,
    body
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }
  notifySuccess(`Permissão de acesso "${tipoOperacao}" removida`);
  return true;
}

export async function removeAllTelasAssociadaPermission({ perfilDeAcessoId }) {
  setLoading(true);
  const data = await api.delete(
    `grupoAcesso/${perfilDeAcessoId}/vinculo-todas-telas`
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }
  notifySuccess(`Permissões de acesso removidas`);
  return true;
}

export async function addLojasAssociadas({ perfilDeAcessoId, loja }) {
  const body = {
    lojaId: loja.id,
  };

  setLoading(true);
  const data = await api.put(
    `grupoAcesso/${perfilDeAcessoId}/adicionar-loja`,
    body
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }

  return true;
}

export async function addAllLojasAssociadas({ perfilDeAcessoId }) {
  setLoading(true);
  const data = await api.put(
    `grupoAcesso/${perfilDeAcessoId}/adicionar-todas-lojas`
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }

  return true;
}

export async function removeLojaAssociada({ perfilDeAcessoId, lojaId }) {
  const body = {
    lojaId,
  };

  setLoading(true);
  const data = await api.put(
    `grupoAcesso/${perfilDeAcessoId}/remover-loja`,
    body
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }

  return true;
}

export async function removeAllLojasAssociada({ perfilDeAcessoId }) {
  setLoading(true);
  const data = await api.delete(
    `grupoAcesso/${perfilDeAcessoId}/remover-todas-lojas`
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }
  notifySuccess(`Lojas removidas`);
  return true;
}

export async function getById(id) {
  setLoading(true);
  const data = await api.get(`GrupoAcesso/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  return { ...data.content };
}

export async function removePerfilDeAcesso(id) {
  setLoading(true);
  const data = await api.delete(`GrupoAcesso/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Registro removido");
  return true;
}
