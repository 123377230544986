import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";
import { Tab, Ul } from "./style";

function SimpleTab({ tabs, activeTab, actionSetTab }) {
  const [activeTabState, setActiveTabState] = useState(
    activeTab ? activeTab : 0
  );

  useEffect(() => {
    if (activeTabState > tabs.length - 1 && tabs.length !== 0) {
      setActiveTabState(tabs.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    if (activeTab) {
      if (activeTab > tabs.length - 1 && tabs.length !== 0) {
        setActiveTabState(tabs.length - 1);
      } else {
        setActiveTabState(activeTab);
      }
    }
  }, [activeTab, tabs.length]);

  return (
    <>
      <Ul id="tabs" className="nav" data-tabs="tabs">
        {tabs?.map((tab, i) => {
          return (
            <Tab key={i}>
              <button
                type="button"
                className={classNames(activeTabState === i && "active")}
                onClick={() => {
                  actionSetTab?.(i);
                  setActiveTabState(i);
                }}
              >
                {tab?.descricao}
              </button>
            </Tab>
          );
        })}
      </Ul>
      <TabContent activeTab={activeTabState}>
        {tabs?.map((tab, i) => {
          return (
            <TabPane tabId={i} key={i}>
              {tab.component}
            </TabPane>
          );
        })}
      </TabContent>
    </>
  );
}

SimpleTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      descricao: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.number,
  actionSetTab: PropTypes.func,
};

SimpleTab.defaultProps = {
  activeTab: 0,
  actionSetTab: () => {},
};

export default SimpleTab;
